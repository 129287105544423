import * as types from "../../type";
import { takeLatest, call, put } from "redux-saga/effects";
import { submitRegisterApi } from "../../../api/register.api";
import { submit_registerSuccess } from "../../actions/register.action";

function* submitSaga(action) {
  try {
    const submit = yield call(submitRegisterApi, action.payload);
    yield put(submit_registerSuccess(submit));
    alert("Đăng ký thành công. Thông tin chi tiết đã được gửi tới Email của bạn. Trân trọng !");
    window.location.reload();
    // window.location.replace("/login");
  } catch (err) {
    yield put({
      type: types.REGISTER_ERROR,
      payload: err.response,
    });
  }
}

export default function* submit_registerSaga() {
  yield takeLatest(types.REGISTER_REQUEST, submitSaga);
}
