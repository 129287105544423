import * as types from "../type";

const initialState = {
  loading: false,
  revenue: [],
  revenueCorporate: 0,
  revenueApp: [],
  totalAppCorporate: 0,
};

const RevenueReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.REVENUE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.REVENUE_SUCCESS:
      return {
        ...state,
        loading: false,
        revenue: action.payload.data.data.revenue,
        // revenueCorporate:
        //   action.payload.data.data.revenueCorporate[0][0].MoneyCorporate,
        revenueApp: action.payload.data.data.revenueApp,
        // totalAppCorporate:
        //   parseInt(
        //     action.payload.data.data.revenueCorporate[0][0].MoneyCorporate
        //   ) + parseInt(action.payload.data.data.revenueApp[0][0].MoneyApp),
      };
    default:
      return {
        ...state,
        loading: false,
      };
  }
};

export default RevenueReducer;
