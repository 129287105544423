import * as types from "../type";

const initialState = {
  loading: false,
  paymentList: [],
};

const PaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentList: action.payload.data.data.getPayment,
      };
    default:
      return {
        ...state,
        loading: false,
      };
  }
};

export default PaymentReducer;
