import * as types from "../../type";
import { takeLatest, call, put } from "redux-saga/effects";
import { checkMobileApi } from "../../../api/register.api";
import { check_mobile_Success } from "../../actions/register.action";

function* checkMobileSaga(action) {
  try {
    const submit = yield call(checkMobileApi, action.payload.data.phone);
    yield put(check_mobile_Success(submit.data.data.checkPhone[0]));
  } catch (err) {
    yield put({
      type: types.REGISTER_CTV_ERROR,
      payload: err.response,
    });
  }
}

export default function* checkSameMobileSaga() {
  yield takeLatest(types.CHECK_MOBILE_REQUEST, checkMobileSaga);
}
