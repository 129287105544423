import React, { useState, useCallback, useEffect } from "react";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import CONFIG from "../../config/config";
import "./InfoUserOrganization.css";

const InfoUserOrganization = (props) => {
  //CONFIG.role = 2 -> thành đoàn HN
  //CONFIG.role = 3 -> trung tâm giới thiệu việc làm HN
  //CONFIG.role = 4 -> ctv

  const [show, setShow] = useState(false);

  const onClose = useCallback(() => {
    setShow(!show);
  }, [show]);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShow(!show);
      }, 1000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <div style={{ paddingLeft: 10, paddingRight: 10 }}>
      <Modal show={show} onHide={onClose}>
        <Modal.Title
          id="example-custom-modal-styling-title"
          style={{ display: "flex", justifyContent: "center" }}
        >
          Copy thành công !
        </Modal.Title>
      </Modal>
      {/* title */}
      <Row xs={12} style={{ alignItems: "center", marginTop: 2 }}>
        <Col xs={12}>
          <h2>{CONFIG.company}</h2>
        </Col>
        <Col></Col>
      </Row>
      {/* truc thuoc */}
      {CONFIG.role !== 4 && (
        <Row xs={12} style={{ alignItems: "center", marginTop: 2 }}>
          <Col xs={12} lg={3}>
            <Form.Label style={{ fontWeight: "bold" }}>Trực thuộc:</Form.Label>
          </Col>
          <Col xs={12} lg={5}>
            {CONFIG.organize.title}
          </Col>
          <Col lg={4}></Col>
        </Row>
      )}
      {/* username */}
      {CONFIG.role !== 2 && (
        <Row xs={12} style={{ alignItems: "center", marginTop: 2 }}>
          <Col xs={12} lg={3}>
            <Form.Label style={{ fontWeight: "bold" }}>Họ và tên:</Form.Label>
          </Col>
          <Col xs={12} lg={5}>
            {CONFIG.fullName}
          </Col>
          <Col lg={4}></Col>
        </Row>
      )}
      {/* link tao ctv */}
      {CONFIG.role !== 4 && (
        <Row xs={12} style={{ alignItems: "center", marginTop: 2 }}>
          <Col xs={12} lg={3}>
            <Form.Label
              style={{
                fontWeight: "bold",
                marginTop: CONFIG.typeScreen === "xs" ? 5 : 0,
              }}
            >
              {CONFIG.role === 4
                ? "Link tạo Đơn vị triển khai"
                : "Link tạo Cộng tác viên"}
              :
            </Form.Label>
          </Col>
          <Col xs={9} lg={5}>
            {CONFIG.ref_link_ctv}
          </Col>
          <Col xs={3} lg={4}>
            <Button
              onClick={() => {
                CONFIG.copyToClipboard(CONFIG.ref_link_ctv);
                onClose();
              }}
              style={{
                color: "#b50829",
                backgroundColor: "#fafafb",
                borderColor: "#fafafb",
              }}
            >
              Copy
            </Button>
          </Col>
        </Row>
      )}
      {/* ma gioi thieu */}
      {CONFIG.role !== 2 && (
        <Row xs={12} style={{ alignItems: "center", marginTop: 2 }}>
          <Col xs={12} lg={3}>
            <Form.Label
              style={{
                fontWeight: "bold",
                marginTop: CONFIG.typeScreen === "xs" ? 5 : 0,
              }}
            >
              Mã giới thiệu:
            </Form.Label>
          </Col>
          <Col xs={9} lg={5}>
            {CONFIG.ref_code}
          </Col>
          <Col xs={3} lg={4}>
            <Button
              onClick={() => {
                CONFIG.copyToClipboard(CONFIG.ref_code);
                onClose();
              }}
              style={{
                color: "#b50829",
                backgroundColor: "#fafafb",
                borderColor: "#fafafb",
              }}
            >
              Copy
            </Button>
          </Col>
        </Row>
      )}
      {/* link doanh nghiep */}
      {CONFIG.role !== 2 && (
        <Row
          xs={12}
          // lg={12}
          className="border_organization"
          style={{ alignItems: "center", marginTop: 2, paddingBottom: 5 }}
        >
          <Col xs={12} lg={3}>
            <Form.Label style={{ fontWeight: "bold" }}>
              Link giới thiệu doanh nghiệp:
            </Form.Label>
          </Col>
          <Col xs={9} lg={5}>
            {CONFIG.ref_link_employee}
          </Col>
          <Col xs={3} lg={4}>
            <Button
              onClick={() => {
                CONFIG.copyToClipboard(CONFIG.ref_link_employee);
                onClose();
              }}
              style={{
                color: "#b50829",
                backgroundColor: "#fafafb",
                borderColor: "#fafafb",
              }}
            >
              Copy
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
};
export default InfoUserOrganization;
