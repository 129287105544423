import * as types from "../type";

export const paymentRequest = (payload) => {
  return {
    type: types.GET_PAYMENT_REQUEST,
    payload: payload,
  };
};

export const paymentSuccess = (payload) => {
  return {
    type: types.GET_PAYMENT_SUCCESS,
    payload: payload,
  };
};

export const paymentError = (payload) => {
  return {
    type: types.GET_PAYMENT_ERROR,
    payload: payload,
  };
};
