import * as types from "../type";

const initialState = {
  titlePartner: "",
  status: null,
  loading: false,
};

const PartnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PARTNER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.PARTNER_ERROR:
      return {
        ...state,
        loading: false,
      };
    case types.PARTNER_SUCCESS:
      return {
        ...state,
        titlePartner: action.payload.data.data.get_partners[0].title,
        loading: false,
      };
    default:
      return {
        ...state,
        loading: false,
      };
  }
};

export default PartnerReducer;
