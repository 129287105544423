import React, { useEffect, useState } from "react";
import API from "../../service/api";
import RegisterTWD from "./RegisterTWD";
import RegisterCTV from "./RegisterCTV";
import CONFIG from "../../config/config";
import ErrorRefCode from "./ErrorRefCode";

const Empty = () => {
  const [getInfo, setGetInfo] = useState("");
  useEffect(() => {
    const splitUrl = window.location.href.split("&");

    const urlElements = splitUrl[0].split("=");
    API.get(`${CONFIG.url}/get-partner?p_ref_prefix=${urlElements[1]}`).then(
      (response) => {
        setGetInfo(response.data.data.province[0] || 0);
      }
    );
  }, []);

  const checkRegister = () => {
    if (getInfo) {
      if (getInfo.level === 2) {
        return <RegisterTWD />;
      } else if (getInfo.level === 3) {
        return (
          <RegisterCTV
            title={
              getInfo.title ||
              "Trung tâm Dịch vụ việc làm và Hỗ trợ Thanh niên Hà Nội"
            }
          />
        );
      } else {
        return <ErrorRefCode />;
      }
    }
  };

  return <>{checkRegister()}</>;
};

export default Empty;
