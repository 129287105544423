import * as types from "../../type";
import { takeLatest, call, put } from "redux-saga/effects";
import { getPartnerApi } from "../../../api/register.api";
import { getPartnerSuccess } from "../../actions/register.action";
function* getPartnerSaga(action) {
  try {
    const partner = yield call(getPartnerApi, action.payload);
    yield put(getPartnerSuccess(partner));
  } catch (err) {
    yield put({
      type: types.PARTNER_ERROR,
      payload: err.response,
    });
  }
}

export default function* partnerSaga() {
  yield takeLatest(types.PARTNER_REQUEST, getPartnerSaga);
}
