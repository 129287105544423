import React, { useState } from "react";
import ihr_logo from "../../assets/images/ihr_logo.png";
import { Form, Button } from "react-bootstrap";
import "./ForgotPass.css";
import { checkMobile } from "../../validation/registerValidate";
import API from "../../service/api";
import CONFIG from "../../config/config";
import { useDispatch } from "react-redux";
import { otpRequest } from "../../redux/actions/otp.action";

const ForgotPass = () => {
  const [phone, setPhone] = useState("");

  const [errorLogin, setErrorLogin] = useState("");
  const dispatch = useDispatch();
  const onSubmit = async () => {
    if (!checkMobile(phone)) {
      setErrorLogin("Số điện thoại không hợp lệ");
      return;
    }

    await API.get(`${CONFIG.url}/check-mobile?p_mobile=${phone}`).then(
      (response) => {
        if (response.data.data.checkPhone[0][0].chkmob === 0) {
          setErrorLogin("Số điện thoại không hợp lệ");
          return;
        } else {
          setErrorLogin("");
          //dispatch action send OTP
          dispatch(otpRequest(phone));

          //continue to verify
          return window.location.replace(`/verify?phone=${phone}`);
        }
      }
    );
  };

  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "rgb(229, 229, 229)",
        minHeight: "100vh",
        height: "auto",
      }}
    >
      {/* logo */}
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <img src={ihr_logo} alt="..." className="img" />
      </div>

      {/* form  */}

      <Form className="forgot_container">
        <h2 className="centerText">Quên mật khẩu</h2>
        <div className="test">
          <label>Dành cho Admin</label>
        </div>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Số điện thoại</Form.Label>
          <Form.Control
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
              setErrorLogin("");
            }}
          />
          {errorLogin && (
            <Form.Label style={{ color: "#b50829", marginTop: 20 }}>
              {errorLogin}
            </Form.Label>
          )}
        </Form.Group>

        <div className="footer">
          <Button
            className="submit_login"
            variant="light"
            onClick={onSubmit}
            style={{ backgroundColor: "#b50829", color: "white" }}
          >
            Nhận mã OTP
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ForgotPass;
