import * as types from "../../type";
import { takeLatest, put } from "redux-saga/effects";
import { logOutSuccess, logOutError } from "../../actions/authen.action";

function* logOutSaga() {
  try {
    window.localStorage.removeItem("accessToken");
    window.localStorage.removeItem("refreshToken");
    window.localStorage.removeItem("infoUser");
    window.localStorage.removeItem("organize");
    yield put(logOutSuccess());
    window.location.replace("/");
  } catch (err) {
    yield put(logOutError());
  }
}

export default function* LogOutSaga() {
  yield takeLatest(types.LOGOUT_REQUEST, logOutSaga);
}
