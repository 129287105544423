import ThemeReducer from "./ThemeReducer";
import RegisterReducer from "./register.reducer";
import { combineReducers } from "redux";
import PartnerReducer from "./partner.reducer";
import AuthenReducer from "./authen.reducer";
import RevenueReducer from "./revenue.reducer";
import ReportReducer from "./report.reducer";
import GetMemberReducer from "./member.reducer";
import ListCustomerJHLReducer from "./listCustomerJHL.reducer";
import OrganizationReducer from "./organization.reducer";
import OTPReducer from "./otp.reducer";
import PaymentReducer from "./payment.reducer";

const rootReducer = combineReducers({
  ThemeReducer,
  register: RegisterReducer,
  partner: PartnerReducer,
  authen: AuthenReducer,
  revenue: RevenueReducer,
  report: ReportReducer,
  member: GetMemberReducer,
  listCustomer: ListCustomerJHLReducer,
  organization: OrganizationReducer,
  otp: OTPReducer,
  payment: PaymentReducer,
});

export default rootReducer;
