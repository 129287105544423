import React, { useEffect } from "react";
import "./Organization.css";
import { Accordion } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { organizationRequest } from "../../redux/actions/organization.action";

const Organization = () => {
  const organizationStore = useSelector((state) => state.organization);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(organizationRequest());
  }, [dispatch]);

  const renderRoot = () => {
    var root;
    // eslint-disable-next-line array-callback-return
    organizationStore.listOrganization.filter((item) => {
      if (item.getOrganC2.level === 1) {
        root = item.getOrganC2.title;
      }
    });
    return root;
  };

  return (
    <div className="organization_container">
      {organizationStore.listOrganization &&
      organizationStore.listOrganization.length !== 0 ? (
        <>
          <p className="title_component_top">Tổ chức</p>
          <p className="title_component_bot">Thêm nhánh</p>
          <Accordion defaultActiveKey="0" className="Accordion">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div className="ip1">{renderRoot()}</div>
              </Accordion.Header>
              <Accordion.Body>
                <Accordion.Item eventKey="1">
                  {organizationStore.listOrganization.map(
                    (item, index) =>
                      item.getOrganC2.level !== 1 && (
                        <Accordion key={index}>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              <div className="ip2">{item.getOrganC2.title}</div>
                            </Accordion.Header>
                            {/* border-bottom: 1px solid #ccc; */}

                            {item.getOrganC3.map((c3, indexC3) => (
                              <Accordion.Body
                                key={indexC3}
                                style={{
                                  borderBottom:
                                    indexC3 === item.getOrganC3.length - 1
                                      ? "none"
                                      : "1px solid #ccc",
                                }}
                                className="ip3"
                              >
                                {c3}
                              </Accordion.Body>
                            ))}
                          </Accordion.Item>
                        </Accordion>
                      )
                  )}
                </Accordion.Item>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </>
      ) : (
        <h2 style={{ marginTop: "30px", textAlign: "center" }}>
          Hiện không có thông tin
        </h2>
      )}
    </div>
  );
};

export default Organization;
