import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Notification from "../pages/Notification";
import Empty from "./register/Empty";
import ErrorRefCode from "./register/ErrorRefCode";
import Login from "./login/Login";
import CONFIG from "../config/config";
import Profile from "../pages/profile/Profile";
import Report from "../pages/report/Report";
import ChangePassword from "../pages/profile/ChangePassword";
import Sale from "../pages/sales/Sale";
import Member from "../pages/member/Member";
import Organization from "../pages/organization/Organization";
import Installer from "../pages/installer/Installer";
import Workers from "../admin89/user/workers/Workers";
import KYC from "../admin89/user/KYC/KYC";
import Company from "../admin89/user/company/Company";
import ForgotPass from "./forgot/ForgotPass";
import VerifyOTP from "./forgot/VerifyOTP";
import Payment from "../pages/payment/Payment";
import ExportPDF from "./common/ExportPDF";
import Support from "../pages/support/Support";

const Routes = () => {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  const infoUser = JSON.parse(localStorage.getItem("infoUser"));
  const organize = JSON.parse(localStorage.getItem("organize"));
  let urlElements = window.location.href;
  let splitUrl = window.location.href.split("=");
  if (infoUser) {
    CONFIG.setInfoUser(infoUser, accessToken, refreshToken, organize);
    if (infoUser.ref_code === null || !infoUser.ref_code) {
      alert(
        "Đã có lỗi xảy ra khi đăng nhập. Vui lòng liên hệ quản trị viên 0982828216 "
      );
    } else {
      CONFIG.onLinkRef(infoUser.ref_code.toLowerCase());
    }
  }

  useEffect(() => {
    CONFIG.setDateTime();

    if (window.innerWidth < 576) {
      console.log("xs");
      CONFIG.typeScreen = "xs";
    } else if (window.innerWidth < 768) {
      console.log("sm");
      CONFIG.typeScreen = "sm";
    } else if (window.innerWidth < 992) {
      console.log("md");
      CONFIG.typeScreen = "md";
    } else if (window.innerWidth < 1200) {
      console.log("lg");
      CONFIG.typeScreen = "lg";
    } else if (window.innerWidth < 1400) {
      console.log("xl");
      CONFIG.typeScreen = "xl";
    } else {
      console.log("xxl");
      CONFIG.typeScreen = "xxl";
    }
  }, []);

  if (!accessToken) {
    if (urlElements.includes("/dangky")) {
      if (splitUrl[0] === `${process.env.REACT_APP_URL}/dangky?ref`) {
        return (
          <Switch>
            <Route path="/dangky" component={Empty} />
          </Switch>
        );
      } else {
        return (
          <Switch>
            <Route path="/error-ref-code" component={ErrorRefCode} />
          </Switch>
        );
      }
    } else if (
      urlElements.includes("/forgot") ||
      urlElements.includes("/verify")
    ) {
      return (
        <Switch>
          <Route path="/forgot-password" component={ForgotPass} />
          <Route path="/verify" component={VerifyOTP} /> 
        </Switch>
      );
    } else {
      return (
        <Switch>
          <Route path="/login" exact component={Login} />
          <Redirect to="/login" />
        </Switch>
      );
    }
  } else {
    return (
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return accessToken ? (
              <Redirect to="/report" />
            ) : (
              <Redirect to="/login" />
            );
          }}
        />
        <Route path="/login" exact component={Login} />

        <Route path="/notification" component={Notification} />
        <Route path="/dangky" component={Empty} />
        <Route path="/error-ref-code" component={ErrorRefCode} />
        <Route path="/profile" exact component={Profile} />
        <Route path="/report" exact component={Report} />
        <Route path="/sales" exact component={Sale} />
        <Route path="/password" exact component={ChangePassword} />
        <Route path="/member" exact component={Member} />
        <Route path="/organization" exact component={Organization} />
        <Route path="/installer" exact component={Installer} />
        <Route path="/payment" exact component={Payment} />
        <Route path="/PDFpayment" exact component={ExportPDF} />
        <Route path="/support" exact component={Support} />

        {/* admin89 */}
        <Route path="/workers" exact component={Workers} />
        <Route path="/kyc" exact component={KYC} />
        <Route path="/company" exact component={Company} />
      </Switch>
    );
  }
};

export default Routes;
