import axios from "axios";
import CONFIG from "../config/config";

export const getAdminActivityReportAPi = (body) => {
	return axios.get(
		`${CONFIG.url}/getAdminActivityReport?p_organization_id=${body.p_organization_id}&p_from_date=${body.p_from_date}&p_to_date=${body.p_to_date}&p_admin_user_id=${CONFIG.admin_user_id}&p_title=${body.p_title}&offset=${body.offset}&limit=${body.limit}`
	);
};

export const getAdminActivityReportTotalAPI = (body) => {
	return axios.get(
		`${CONFIG.url}/getAdminActivityReportTotal?p_organization_id=${body.p_organization_id}&p_from_date=${body.p_from_date}&p_to_date=${body.p_to_date}&p_admin_user_id=${CONFIG.admin_user_id}&p_title=${body.p_title}`
	);
};

export const getAdminActivityReportTotalAppAPI = (body) => {
	return axios.get(
		`${CONFIG.url}/getAdminActivityReportTotalApp?p_organization_id=${body.p_organization_id}&p_from_date=${body.p_from_date}&p_to_date=${body.p_to_date}&p_admin_user_id=${CONFIG.admin_user_id}&p_title=${body.p_title}`
	);
};

export const getAdminActivityReportTotalCorpAPI = (body) => {
	return axios.get(
		`${CONFIG.url}/getAdminActivityReportTotalCorp?p_organization_id=${body.p_organization_id}&p_from_date=${body.p_from_date}&p_to_date=${body.p_to_date}&p_admin_user_id=${CONFIG.admin_user_id}&p_title=${body.p_title}`
	);
};

// ${CONFIG.role}
export const getBranchApi = () => {
	return axios.get(
		`${CONFIG.url}/getBranch?p_admin_user_id=${CONFIG.admin_user_id}`
	);
};

export const getSaleReportAPi = (body) => {
	return axios.get(
		`${CONFIG.url}/getSaleReport?p_organization_id=${body.p_organization_id}&p_from_date=${body.p_from_date}&p_to_date=${body.p_to_date}&p_admin_user_id=${CONFIG.admin_user_id}&p_title=${body.p_title}`
	);
};
