import * as types from "../type";

export const loginRequest = (payload) => {
  return {
    type: types.LOGIN_REQUEST,
    payload: payload,
  };
};

export const loginSuccess = (payload) => {
  return {
    type: types.LOGIN_SUCCESS,
    payload: payload,
  };
};

export const loginError = (payload) => {
  return {
    type: types.LOGIN_ERROR,
    payload: payload,
  };
};

//LOG OUT
export const logOutRequest = (payload) => {
  return {
    type: types.LOGOUT_REQUEST,
    payload: payload,
  };
};

export const logOutSuccess = (payload) => {
  return {
    type: types.LOGOUT_SUCCESS,
    payload: payload,
  };
};

export const logOutError = (payload) => {
  return {
    type: types.LOGOUT_ERROR,
    payload: payload,
  };
};
