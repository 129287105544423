import * as types from "../type";

const initialState = {
  loading: false,
  access_token: "",
  refresh_token: "",
  infoUser: "",
};

const AuthenReducer = (state = initialState, action) => {
  switch (action.type) {
    //log in
    case types.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        access_token: "",
        refresh_token: "",
        infoUser: "",
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        access_token: action.payload.data.accessToken,
        refresh_token: action.payload.data.refreshToken,
        infoUser: action.payload.data.infoUser,
      };

      case types.LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        access_token: "",
        refresh_token: "",
        infoUser: "",
      };
    //log out
    case types.LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        access_token: "",
        refresh_token: "",
        infoUser: "",
      };
    case types.LOGOUT_ERROR:
      return {
        ...state,
        loading: true,
      };

    //default
    default:
      return {
        ...state,
        loading: false,
      };
  }
};

export default AuthenReducer;
