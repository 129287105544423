import * as types from "../type";

export const organizationRequest = (payload) => {
  return {
    type: types.GET_ORGANIZATION_REQUEST,
    payload: payload,
  };
};

export const organizationSuccess = (payload) => {
  return {
    type: types.GET_ORGANIZATION_SUCCESS,
    payload: payload,
  };
};

export const organizationError = (payload) => {
  return {
    type: types.GET_ORGANIZATION_ERROR,
    payload: payload,
  };
};
