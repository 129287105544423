import React, { useEffect, useState } from "react";
import { Container, Col, Row, Button, ToastContainer } from "react-bootstrap";
import "./ExportPDF.css";
import logo from "../../assets/images/logo-login.png";
import API from "../../service/api";
import CONFIG from "../../config/config";
import money_format from "../../config/moneyFormat";
import { getText } from "number-to-text-vietnamese";
import Pdf from "react-to-pdf";
import Loading from "../Loading";
import moment from "moment";

const ref = React.createRef();

const ExportPDF = (props) => {
  const [info, setInfo] = useState({
    amount: 0,
    content: "Đang tải dữ liệu ...",
    email: "Đang tải dữ liệu ...",
    month: 12,
    partner_name: "Đang tải dữ liệu ...",
    partner_payment_id: 1,
    ref_code: "Đang tải dữ liệu ...",
    updated_at: "2021-12-27T14:20:05.000Z",
    year: 2021,
  });

  useEffect(() => {
    const splitUrl = window.location.href.split("&");
    const urlElements = splitUrl[0].split("=");

    API.get(
      `${CONFIG.url}/getPDFPayment?p_partner_payment_id=${urlElements[1]}`
    ).then((response) => {
      setInfo(response.data.data.getPDFPayment[0]);
    });
  }, []);

  const numberToVND = (amount) => {
    let textToString = getText(amount).toString();
    return textToString.replace(textToString[0], textToString[0].toUpperCase());
  };

  const fileName = (partner_payment_id) => {
    return `ihr${info.partner_payment_id}.pdf`;
  };

  return (
    <>
      <Container
        className="PDFpayment_container"
        style={{ maxWidth: 780 }}
        ref={ref}
      >
        {/* header */}
        <div className="header">
          <div className="sidebar__logo">
            <img src={logo} alt="company logo" />
          </div>
          <div style={{ paddingRight: "20px" }}>
            <label className="company_name_size">
              CÔNG TY CỔ PHẦN CUNG ỨNG NHÂN LỰC CHẤT LƯỢNG CAO CLC
            </label>
            <label className="normal_size">
              Số 1 Nguyễn Quý Đức, Thanh Xuân Bắc, Thanh Xuân, Hà Nội
            </label>
            <label className="normal_size">
              Hotline: 1900.866821 &nbsp; Website:{" "}
              <label style={{ color: "#B50829" }}>https://i-hr.vn </label>{" "}
              &nbsp; Email:
              <label style={{ color: "#B50829" }}>info@i-hr.vn</label>
            </label>
          </div>
        </div>

        {/* body */}
        <div>
          {/* title */}
          <div className="body_title">
            <h2>PHIẾU THANH TOÁN</h2>
            <label style={{ marginTop: "10px" }}>
              Kỳ tháng {info.month}/{info.year}
            </label>
          </div>

          {/* context */}
          <div className="body_context">
            <Row xs={2} md={4} lg={12}>
              <Col className="bold_text">Ngày:</Col>
              <Col lg={8}>
                {moment(
                  info.updated_at.replace("T", " ").replace(".000Z", ""),
                  moment.ISO_8601
                ).format("DD-MM-YYYY hh:mm:ss")}
              </Col>
            </Row>
            <Row xs={2} md={4} lg={12}>
              <Col className="bold_text">Số hóa đơn:</Col>
              <Col lg={8}>{info.partner_payment_id || "Đang cập nhật ..."}</Col>
            </Row>
            <Row xs={2} md={4} lg={12}>
              <Col className="bold_text">Mã đối tác:</Col>
              <Col lg={8}>{info.ref_code || "Đang cập nhật ..."}</Col>
            </Row>
            <Row xs={2} md={4} lg={12}>
              <Col className="bold_text">Tên đối tác:</Col>
              <Col lg={8}>{info.partner_name || "Đang cập nhật ..."}</Col>
            </Row>
            <Row xs={2} md={4} lg={12}>
              <Col className="bold_text">Email:</Col>
              <Col lg={8}>{info.email || "Đang cập nhật ..."}</Col>
            </Row>
            {/* content */}
            <div style={{ paddingTop: "30px" }}>
              <Row md={12}>
                <Col
                  md={6}
                  className="border_table"
                  style={{ fontWeight: "bold" }}
                >
                  Nội dung
                </Col>
                <Col
                  md={3}
                  className="border_table"
                  style={{ fontWeight: "bold", textAlign: "end" }}
                >
                  Kỳ thanh toán
                </Col>
                <Col
                  md={3}
                  className="border_table"
                  style={{ fontWeight: "bold", textAlign: "end" }}
                >
                  Số tiền
                </Col>
              </Row>
              {/* money */}
              <Row md={12}>
                <Col className="border_table" md={6}>
                  {info.content}
                </Col>
                <Col
                  className="border_table"
                  style={{ textAlign: "end" }}
                  md={3}
                >
                  {info.month}/{info.year}
                </Col>
                <Col
                  className="border_table"
                  style={{ textAlign: "end" }}
                  md={3}
                >
                  {money_format(info.amount * 1000)} đ
                </Col>
              </Row>
              {/* VAT */}
              <Row md={12}>
                <Col md={4}></Col>
                <Col
                  className="border_table"
                  style={{ textAlign: "end" }}
                  md={5}
                >
                  Thuế VAT (đã bao gồm)
                </Col>
                <Col
                  className="border_table"
                  style={{ textAlign: "end" }}
                  md={3}
                >
                  0 đ
                </Col>
              </Row>

              {/* phi dich vu */}
              <Row md={12}>
                <Col md={4}></Col>
                <Col
                  className="border_table"
                  md={5}
                  style={{ textAlign: "end" }}
                >
                  Phí dịch vụ
                </Col>
                <Col
                  className="border_table"
                  style={{ textAlign: "end" }}
                  md={3}
                >
                  0 đ
                </Col>
              </Row>
              {/* tong */}
              <Row style={{ marginTop: "20px" }} md={12}>
                <Col md={4}></Col>
                <Col
                  md={5}
                  style={{
                    textAlign: "end",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  Tổng
                </Col>
                <Col
                  style={{
                    fontWeight: "bold",
                    fontSize: "18px",
                    textAlign: "end",
                  }}
                  md={3}
                >
                  {money_format(info.amount * 1000)} đ
                </Col>
              </Row>
              {/* money to text */}
              <Row style={{ marginTop: "20px" }}>
                <Col style={{ textAlign: "end", fontStyle: "italic" }}>
                  Bằng chữ: <span>{numberToVND(info.amount * 1000 || 0)}</span>{" "}
                  đồng.
                </Col>
              </Row>
              {/* end table */}
            </div>
          </div>
        </div>

        {/* footer */}
        <div
          style={{
            marginLeft: "10px",
            marginTop: "50px",
            marginBottom: "50px",
          }}
        >
          <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
            Ghi chú:
          </span>
          <div style={{ marginLeft: "15px" }}>
            <label style={{ fontStyle: "italic" }}>
              • Thanh toán sẽ được thực hiện sau 05 ngày làm việc kể từ ngày đến
              hạn.
            </label>
            <label style={{ fontStyle: "italic" }}>
              • Nếu có bất kỳ yêu cầu nào, vui lòng thông báo cho CLC trước 04
              ngày làm việc
            </label>
            <label style={{ fontStyle: "italic" }}>
              • Hotline: 1900 866821 - Email: info@i-hr.vn
            </label>
          </div>
        </div>
      </Container>
      <div
        style={{ display: "flex", justifyContent: "center", padding: "30px" }}
      >
        <Pdf targetRef={ref} filename={fileName(info.partner_payment_id)}>
          {({ toPdf }) => <Button onClick={toPdf}>Tải về file PDF</Button>}
        </Pdf>
      </div>
      <ToastContainer />
      <Loading isLoading={!info} />
    </>
  );
};

export default ExportPDF;
