import React, { useState, useEffect } from "react";
import ihr_logo from "../../assets/images/ihr_logo.png";
import { Form, Button } from "react-bootstrap";
import "./Verify.css";
import { useDispatch, useSelector } from "react-redux";
import { loginRequest } from "../../redux/actions/authen.action";
import API from "../../service/api";
import CONFIG from "../../config/config";
import { ToastContainer } from "react-toastify";
import Loading from "../Loading";
import { otpStatuses } from "./../../validation/constant";

const VerifyOTP = () => {
  const splitUrl = window.location.href.split("&");
  const urlElements = splitUrl[0].split("=");

  const [info, setInfo] = useState({
    phone: urlElements[1] || "",
    otp: "",
    newPass: "",
    newPassAgain: "",
  });

  const emptyErr = {
    otpErr: "",
    passErr: "",
    reTypePassErr: "",
  };

  const [errorOTP, setErrorOTP] = useState(emptyErr);
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.authen);

  const onSubmit = async () => {
    // console.log("onSubmit", !info.newPass);

    var otpErr = "";
    var newpassErr = "";
    var newPassAgain = "";

    if (!info.otp) {
      otpErr = "OTP không được bỏ trống";
    }
    if (!info.newPass) {
      newpassErr = "Mật khẩu mới không được bỏ trống";
    }
    if (info.newPass.length < 6) {
      newpassErr = "Mật khẩu mới phải dài hơn 6 ký tự";
    }
    if (!info.newPassAgain) {
      newPassAgain = "Mật khẩu mới không được bỏ trống";
    }
    if (info.newPass !== info.newPassAgain) {
      newPassAgain = "Mật khẩu mới chưa trùng khớp";
    }
    setErrorOTP({
      otpErr: otpErr,
      passErr: newpassErr,
      reTypePassErr: newPassAgain,
    });

    if (!otpErr && !newpassErr && !newPassAgain) {
      await API.post(`${CONFIG.url}/verifyOTP`, {
        mobile: info.phone,
        code: info.otp,
        password: info.newPass,
      })
        .then((response) => {
          if (response.data.data.otpStatus === otpStatuses.INVALID) {
            otpErr = "Vui lòng thử lại";
          }
          if (response.data.data.otpStatus === otpStatuses.WRONG_CODE) {
            otpErr = "Sai mã OTP. Vui lòng thử lại";
          }
          if (response.data.data.otpStatus === otpStatuses.NON_USER) {
            otpErr = "Số điện thoại chưa được đăng ký";
          }
          if (response.data.data.otpStatus === otpStatuses.VALID) {
            let infoLogin = {
              phone: response.data.data.user.username,
              password: info.newPass,
            };
            dispatch(loginRequest(infoLogin));
          }
          setErrorOTP({
            otpErr: otpErr,
            passErr: newpassErr,
            reTypePassErr: newPassAgain,
          });
        })
        .catch((e) => {
          console.log("e,  ", e);
        });
    }
  };

  useEffect(() => {
    if (auth.access_token) {
      return window.location.replace(`/report`);
    }
  }, [auth]);

  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "rgb(229, 229, 229)",
        minHeight: "100vh",
        height: "auto",
      }}
    >
      {/* logo */}
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <img src={ihr_logo} alt="..." className="img" />
      </div>

      {/* form  */}

      <Form className="verify_container">
        <h2 className="centerText">Quên mật khẩu</h2>
        <div className="test">
          <label>Dành cho Admin</label>
        </div>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Số điện thoại</Form.Label>
          <Form.Control
            value={info.phone}
            onChange={(e) => {
              setInfo({ ...info, phone: e.target.value });
              setErrorOTP(emptyErr);
            }}
            disabled={true}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Mã OTP</Form.Label>
          <Form.Control
            value={info.otp}
            onChange={(e) => {
              setInfo({ ...info, otp: e.target.value });
              setErrorOTP(emptyErr);
            }}
          />
          {errorOTP.otpErr && (
            <Form.Label style={{ color: "#b50829", marginTop: 20 }}>
              {errorOTP.otpErr}
            </Form.Label>
          )}
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Mật khẩu mới</Form.Label>
          <Form.Control
            type="password"
            value={info.newPass}
            onChange={(e) => {
              setInfo({ ...info, newPass: e.target.value });
              setErrorOTP(emptyErr);
            }}
          />
          {errorOTP.passErr && (
            <Form.Label style={{ color: "#b50829", marginTop: 20 }}>
              {errorOTP.passErr}
            </Form.Label>
          )}
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Nhập lại mật khẩu mới</Form.Label>
          <Form.Control
            type="password"
            value={info.newPassAgain}
            onChange={(e) => {
              setInfo({ ...info, newPassAgain: e.target.value });
              setErrorOTP(emptyErr);
            }}
          />
          {errorOTP.reTypePassErr && (
            <Form.Label style={{ color: "#b50829", marginTop: 20 }}>
              {errorOTP.reTypePassErr}
            </Form.Label>
          )}
        </Form.Group>
        <div className="footer">
          <Button
            className="submit_login"
            variant="light"
            onClick={onSubmit}
            style={{ backgroundColor: "#b50829", color: "white" }}
          >
            &nbsp;Xác nhận&nbsp;
          </Button>
        </div>
      </Form>
      <ToastContainer />
      <Loading isLoading={auth.loading} />
    </div>
  );
};

export default VerifyOTP;
