import React, { useEffect, useState } from "react";
import "./Payment.css";
import { Form, Container, Row, Col, ToastContainer } from "react-bootstrap";
import CONFIG from "../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { paymentRequest } from "../../redux/actions/payment.action";
import Table from "../../components/table/Table";
import Loading from "../../components/Loading";
import money_format from "../../config/moneyFormat";
import moment from "moment";

const Payment = () => {
  const dispatch = useDispatch();
  const paymentStore = useSelector((state) => state.payment);
  const [yearSelected, setYearSelected] = useState("0");

  const arrayYear = [
    "2021",
    "2022",
    "2023",
    "2024",
    "2025",
    "2026",
    "2027",
    "2028",
    "2029",
    "2030",
    "2031",
    "2032",
    "2033",
    "2034",
    "2035",
    "2036",
    "2037",
    "2038",
    "2039",
    "2040",
    "2041",
    "2042",
    "2043",
    "2044",
    "2045",
    "2046",
    "2047",
    "2048",
    "2049",
    "2050",
  ];

  useEffect(() => {
    dispatch(paymentRequest(0));
  }, [dispatch]);

  const corporateTableHead = [
    "",
    "Thời gian",
    "Kỳ thanh toán",
    "Đối tác",
    "Nội dung",
    "Số tiền (VND)",
    "Trạng thái",
  ];

  const renderHead = (item, index) => (
    <th style={{ border: "1px solid #ccc" }} key={index}>
      {item}
    </th>
  );
  const renderBody = (item, index) => (
    <tr key={index}>
      <td style={{ border: "1px solid #ccc", textAlign: "center" }}>
        {index + 1}
      </td>
      <td style={{ border: "1px solid #ccc" }}>
        {moment(
          item.created_at.replace("T", " ").replace(".000Z", ""),
          moment.ISO_8601
        ).format("DD-MM-YYYY hh:mm:ss")}
      </td>
      <td style={{ border: "1px solid #ccc", textAlign: "center" }}>
        {item.month}/{item.year}
      </td>
      <td style={{ border: "1px solid #ccc" }}>{item.partner_name}</td>
      <td style={{ border: "1px solid #ccc" }}>{item.content}</td>
      <td style={{ border: "1px solid #ccc", textAlign: "center" }}>
        {money_format(item.amount * 1000)}
      </td>

      <td
        style={{
          border: "1px solid #ccc",
          textAlign: "center",
        }}
      >
        {item.status === 1 && (
          <label style={{ color: "#B50829" }}>Chưa thanh toán</label>
        )}
        {item.status === 2 && (
          <a
            href={`${process.env.REACT_APP_URL}/PDFpayment?partner_payment_id=${item.partner_payment_id}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <span style={{ color: "#039600" }}>Hóa đơn</span>
          </a>
        )}
        {item.status === 3 && (
          <label style={{ color: "#faa005" }}>Khiếu nại, chờ cập nhật</label>
        )}
      </td>
    </tr>
  );

  const onChangeYear = (year) => {
    setYearSelected(year);
    dispatch(paymentRequest(year));
  };

  return (
    <Container>
      <h2>Thanh toán</h2>
      <Row
        style={{ alignItems: "center", marginTop: "20px" }}
        xs={1}
        md={4}
        lg={6}
      >
        <Col
          xxl={1}
          xl={1}
          lg={1}
          className="year_filter"
          style={{ marginLeft: CONFIG.typeScreen === "xs" ? "0px" : "10px" }}
        >
          Năm
        </Col>
        <Col xxl={3}>
          <Form.Select
            value={yearSelected}
            onChange={(e) => {
              onChangeYear(e.target.value);
            }}
            xs={12}
            lg={3}
          >
            <option value="0">Tất cả</option>
            {arrayYear.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>

      <div className="row" style={{ marginTop: 10 }}>
        <div className="col-12">
          <div className="card">
            <div className="card__body">
              {paymentStore.paymentList &&
              paymentStore.paymentList.length !== 0 ? (
                <Table
                  limit="50"
                  headData={corporateTableHead}
                  renderHead={(item, index) => renderHead(item, index)}
                  bodyData={paymentStore.paymentList}
                  renderBody={(item, index) => renderBody(item, index)}
                  count={paymentStore.paymentList.length}
                />
              ) : (
                <h2 style={{ marginTop: "30px", textAlign: "center" }}>
                  Hiện không có thông tin
                </h2>
              )}
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
      <Loading isLoading={paymentStore.loading} />
    </Container>
  );
};
export default Payment;
