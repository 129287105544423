import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import ClientCaptcha from "react-client-captcha";
import { useDispatch, useSelector } from "react-redux";
import showPassWord from "../../assets/images/showPassWord.png";
import {
  getPartner,
  submit_registerRequest,
} from "../../redux/actions/register.action";
import "./Register.css";
import { checkMobile, checkEmail } from "../../validation/registerValidate";
import API from "../../service/api";
import CONFIG from "../../config/config";

const RegisterTWD = () => {
  const titleTWD = useSelector((state) => state.partner);

  const [actions, setActions] = useState({
    phone: "",
    pass: "",
    name: "",
    male: "0",
    organization_id: "1",
    department_name: "",
    email: "",
    identity: "",
    ref_code: "",
  });

  const [errors, setErrors] = useState({
    phoneErr: "",
    passErr: "",
    nameErr: "",
    department_nameErr: "",
    emailErr: "",
    captchaErr: "",
  });

  const [isShowPassWord, setShowPassWord] = useState(false);
  const [captchaCode, setCaptchaCode] = useState("");
  const [inputCaptcha, setInputCaptcha] = useState("");
  const [getInfo, setGetInfo] = useState("");

  const dispatch = useDispatch();

  const splitUrl = window.location.href.split("&");

  const urlElements = splitUrl[0].split("=");

  useEffect(() => {
    // dispatch(getProvince("hn"));
    let url = window.location.href.split("=");
    API.get(`${CONFIG.url}/get-partner?p_ref_prefix=${url[1]}`).then(
      (response) => {
        setGetInfo(response.data.data.province[0]);
      }
    );
    dispatch(getPartner(url[1]));
  }, [dispatch]);

  const submit_register = async () => {
    setErrors({
      phoneErr: "",
      passErr: "",
      nameErr: "",
      department_nameErr: "",
      emailErr: "",
      captchaErr: "",
    });

    //check ref_code exits
    var response_check_ref;
    await API.get(`${CONFIG.url}/check-ref-code?p_ref_code=${urlElements[1]}`)
      .then((response) => {
        response_check_ref = response;
      })
      .catch((e) => {
        alert("Mã giới thiệu không hợp lệ. Vui lòng liên hệ 0982828216");
        return;
      });
    if (response_check_ref === undefined) {
      alert("Mã giới thiệu không hợp lệ. Vui lòng liên hệ 0982828216");
      return;
    }

    var phoneErr = "";
    var passErr = "";
    var nameErr = "";
    var emailErr = "";
    var captchaErr = "";
    var department_nameErr = "";
    var isPassValidate = true;

    //check phone exits
    API.get(`${CONFIG.url}/check-mobile?p_mobile=${actions.phone}`).then(
      (response) => {
        if (response.data.data.checkPhone[0][0].chkmob === 1) {
          setErrors({ ...actions, phoneErr: "Số điện thoại đã được đăng ký" });
          return;
        }
      }
    );

    if (!checkMobile(actions.phone)) {
      isPassValidate = false;
      phoneErr = "Vui lòng nhập đúng số điện thoại";
    }

    if (actions.pass.length < 6) {
      isPassValidate = false;
      passErr = "Mật khẩu ít nhất 6 ký tự";
    }
    if (!actions.pass) {
      isPassValidate = false;
      passErr = "Vui lòng nhập mật khẩu";
    }
    if (!actions.name) {
      isPassValidate = false;
      nameErr = "Vui lòng nhập tên";
    }
    if (!actions.department_name) {
      isPassValidate = false;
      department_nameErr = "Vui lòng nhập tên cơ quan / đơn vị";
    }
    if (!checkEmail(actions.email)) {
      isPassValidate = false;
      emailErr = "Vui lòng nhập Email đúng định dạng";
    }
    if (!actions.email) {
      isPassValidate = false;
      emailErr = "Vui lòng nhập Email";
    }
    if (captchaCode !== inputCaptcha) {
      isPassValidate = false;
      captchaErr = "Mã xác nhận chưa đúng";
    }
    if (!isPassValidate) {
      setErrors({
        phoneErr: phoneErr,
        passErr: passErr,
        nameErr: nameErr,
        emailErr: emailErr,
        captchaErr: captchaErr,
        department_nameErr: department_nameErr,
      });
      return;
    }

    dispatch(
      submit_registerRequest({
        ...actions,
        phone: actions.phone.trim(),
        organization_id: getInfo.organization_id,
        ref_code: urlElements[1],
      })
    );
  };
  return (
    <>
      {/* title */}
      {/* <Container style={{  }}></Container> */}

      {/* dien thoai && mat khau */}
      <Container className="register_container" style={{ maxWidth: 760 }}>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <h1>ĐĂNG KÝ CÁN BỘ TRIỂN KHAI</h1>
        </div>
        {titleTWD && (
          <label style={{ fontWeight: "bold" }}>{titleTWD.titlePartner}</label>
        )}
        <label style={{ display: "block" }}>
          · Form đăng ký mã giới thiệu cho cán bộ triển khai hệ thống i-HR tại
          địa phương
        </label>
        <label style={{ display: "block" }}>
          · Website: https://i-hr.vn - Hỗ trợ kỹ thuật: 1900 866821
        </label>
        <label>
          · Mã giới thiệu và link đăng nhập sẽ được gửi qua SMS và Email
        </label>

        <Row style={{ marginTop: "30px" }}>
          <Col xs="12" lg="6">
            <Form.Label htmlFor="basic-url11" style={{ fontWeight: "bold" }}>
              Điện thoại
            </Form.Label>
            <Form.Label style={{ color: "red" }}>*</Form.Label>
            <InputGroup
              className="mb-3"
              onChange={(e) => {
                setActions({
                  ...actions,
                  phone: e.target.value,
                });
              }}
            >
              <FormControl
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </InputGroup>
            {errors.phoneErr && (
              <Form.Label style={{ color: "red" }}>
                {errors.phoneErr}
              </Form.Label>
            )}
          </Col>
          <Col xs="12" lg="6">
            <Form.Label htmlFor="basic-url2" style={{ fontWeight: "bold" }}>
              Mật khẩu
            </Form.Label>
            <Form.Label style={{ color: "red" }}>*</Form.Label>
            <InputGroup
              className="mb-3 "
              style={{ position: "relative" }}
              onChange={(e) => {
                setActions({
                  ...actions,
                  pass: e.target.value,
                });
              }}
            >
              <img
                className="td"
                onClick={() => {
                  setShowPassWord(!isShowPassWord);
                }}
                width="20px"
                height="20px"
                src={isShowPassWord ? showPassWord : showPassWord}
                alt="..."
              />
              <FormControl
                type={isShowPassWord ? "text" : "password"}
                aria-describedby="basic-addon3"
              />
            </InputGroup>
            {errors.passErr && (
              <Form.Label style={{ color: "red" }}>{errors.passErr}</Form.Label>
            )}
          </Col>
        </Row>

        {/* ho ten && gioi tinh */}
        <Row>
          <Col xs="12" lg="6">
            <Form.Label htmlFor="basic-url12" style={{ fontWeight: "bold" }}>
              Họ và tên
            </Form.Label>
            <Form.Label style={{ color: "red" }}>*</Form.Label>
            <InputGroup
              className="mb-3"
              onChange={(e) => {
                setActions({
                  ...actions,
                  name: e.target.value,
                });
              }}
            >
              <FormControl
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              />
            </InputGroup>
            {errors.nameErr && (
              <Form.Label style={{ color: "red" }}>{errors.nameErr}</Form.Label>
            )}
          </Col>
          <Col xs="12" lg="6">
            <Form.Label htmlFor="basic-url" style={{ fontWeight: "bold" }}>
              Giới tính
            </Form.Label>
            <Form.Select
              value={actions.male}
              onChange={(e) => {
                setActions({
                  ...actions,
                  male: e.target.value,
                });
              }}
              aria-label="Default select example"
            >
              <option value="0">Nam</option>
              <option value="1">Nữ</option>
            </Form.Select>
          </Col>
        </Row>
        {/* Tinh/Thanh doan  */}
        <Row style={{ marginTop: "10px" }}>
          <Col>
            <Form.Label htmlFor="basic-url1" style={{ fontWeight: "bold" }}>
              Tỉnh / Thành Đoàn
            </Form.Label>
            <Form.Label style={{ color: "red" }}>*</Form.Label>
            <Form.Select
              value={actions.organization_id}
              disabled={true}
              aria-label="Default select example"
            >
              <option>{getInfo.title}</option>
            </Form.Select>
          </Col>
        </Row>

        {/* co quan / don vi  */}
        <Row style={{ marginTop: "20px" }}>
          <Col>
            <Form.Label htmlFor="basic-url2" style={{ fontWeight: "bold" }}>
              Cơ quan / Đơn vị
            </Form.Label>
            <Form.Label style={{ color: "red" }}>*</Form.Label>
            <InputGroup
              className="mb-3"
              onChange={(e) => {
                setActions({
                  ...actions,
                  department_name: e.target.value,
                });
              }}
            >
              <FormControl id="basic-url" aria-describedby="basic-addon3" />
            </InputGroup>
            {errors.department_nameErr && (
              <Form.Label style={{ color: "red" }}>
                {errors.department_nameErr}
              </Form.Label>
            )}
          </Col>
        </Row>

        {/* Email CMND */}
        <Row>
          <Col xs="12" lg="6">
            <Form.Label htmlFor="basic-url4" style={{ fontWeight: "bold" }}>
              Email
            </Form.Label>
            <Form.Label style={{ color: "red" }}>*</Form.Label>
            <InputGroup
              className="mb-3"
              onChange={(e) => {
                setActions({
                  ...actions,
                  email: e.target.value,
                });
              }}
            >
              <FormControl
                id="basic-url5"
                aria-describedby="basic-addon3"
                placeholder="example@gmail.com"
              />
            </InputGroup>
            {errors.emailErr && (
              <Form.Label style={{ color: "red" }}>
                {errors.emailErr}
              </Form.Label>
            )}
          </Col>
          <Col xs="12" lg="6">
            <Form.Label htmlFor="basic-url6" style={{ fontWeight: "bold" }}>
              Số CMND/ CCCD
            </Form.Label>
            <InputGroup
              className="mb-3"
              onChange={(e) => {
                setActions({
                  ...actions,
                  identity: e.target.value,
                });
              }}
            >
              <FormControl id="basic-url7" aria-describedby="basic-addon3" />
            </InputGroup>
          </Col>
        </Row>

        {/* cap cha */}
        <Row>
          <Col
            xs={{ span: 0, offset: 0 }}
            lg={{ span: 5, offset: 4 }}
            xl={{ span: 6, offset: 3 }}
            md={{ span: 0, offset: 0 }}
          >
            <Form.Label htmlFor="basic-url9" style={{ fontWeight: "bold" }}>
              Nhập mã xác nhận
            </Form.Label>
            <InputGroup
              onChange={(e) => {
                setInputCaptcha(e.target.value);
              }}
              className="mb-3"
            >
              <FormControl id="basic-url10" aria-describedby="basic-addon3" />
              <div style={{ marginLeft: "10px" }}>
                <ClientCaptcha
                  captchaCode={(captchaCode) => {
                    setCaptchaCode(captchaCode);
                  }}
                />
              </div>
            </InputGroup>
            {errors.captchaErr && (
              <Form.Label style={{ color: "red" }}>
                {errors.captchaErr}
              </Form.Label>
            )}
          </Col>
        </Row>

        {/* submit */}
        <Row
          style={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Col xs={6} lg={4} md={5} xl={5}>
            <Button
              className="btn_register"
              onClick={submit_register}
              variant="danger"
            >
              Đăng ký
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RegisterTWD;
