import { all } from 'redux-saga/effects';
import registerSaga from './register/register.saga';
import partnerSaga from './register/getPartner.saga';
import submit_registerSaga from './register/submit_register.saga';
import submit_registerCTVSaga from './register/registerCTV.saga';
import checkSameMobileSaga from './register/check_mobile.saga';
import authencationSaga from './authen/authen.saga';
import LogOutSaga from './authen/logOut.saga';
import RevenueSaga from './revenue/revenue.saga';
import GetBranchSaga from './report/getBranch.saga';
import ReportSaga from './report/getReport.saga';
import ReportSaleSaga from './report/getSaleReport.saga';
import getMemberSaga from './members/member.saga';
import updateInfoUserSaga from './members/updateInfoUser.saga';
import customerSaga from './customer/customer.saga';
import organizationSaga from './organization/organization.saga';
import OTPSaga from './otp/otp.saga';
import paymentSaga from './payment/payment.saga';

//Provinces

function* rootSaga() {
  yield all([
    //register
    registerSaga(),
    partnerSaga(),
    submit_registerSaga(),
    submit_registerCTVSaga(),
    checkSameMobileSaga(),
    authencationSaga(),
    LogOutSaga(),
    RevenueSaga(),
    ReportSaga(),
    GetBranchSaga(),
    ReportSaleSaga(),
    getMemberSaga(),
    updateInfoUserSaga(),
    customerSaga(),
    organizationSaga(),
    OTPSaga(),
    paymentSaga(),
  ]);
}

export default rootSaga;
