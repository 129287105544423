/* eslint-disable array-callback-return */
import React from "react";
import CONFIG from "../../config/config";

const Support = () => {
  return (
    <>
      <div className="row" style={{ marginTop: 10 }}>
        <div className="col-12">
          <div className="card">
            <h2 style={{ marginTop: "30px", textAlign: "center" }}>
              Hỗ trợ: {CONFIG.phone_admin}
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
