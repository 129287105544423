import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import "./table.css";

const Table = (props) => {
  const initDataShow =
    props.limit && props.bodyData
      ? props.bodyData.slice(0, Number(props.limit))
      : props.bodyData;
  const [dataShow, setDataShow] = useState(initDataShow);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const selectPage = (data) => {
    props.paginateReport(data.selected * 50);
    setPage(data.selected);
  };
  useEffect(() => {
    setPage(0);
    setPageCount(Math.ceil(props.count / Number(props.limit)));
  }, [props.count, props.limit]);

  useEffect(() => {
    setDataShow(props.bodyData);
  }, [props.bodyData]);
  return (
    <div>
      <div className="table-wrapper">
        <table>
          {props.headData && props.renderHead ? (
            <thead>
              <tr style={{ textAlign: "center" }}>
                {props.headData.map((item, index) =>
                  props.renderHead(item, index)
                )}
              </tr>
            </thead>
          ) : null}
          {props.bodyData && props.renderBody ? (
            <tbody>
              {dataShow.map((item, index) => props.renderBody(item, index))}
            </tbody>
          ) : null}
        </table>
      </div>
      <ReactPaginate
        forcePage={page}
        previousLabel={"Trước"}
        nextLabel={"Tiếp"}
        breakLabel={"..."}
        pageCount={pageCount}
        marginPagesDisplayed={3}
        pageRangeDisplayed={6}
        onPageChange={selectPage}
        containerClassName={"pagination justify-content-center mt-4"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
      />
    </div>
  );
};

export default Table;
