import React from "react";
import "./button.css";
import { Row } from "react-bootstrap";
import CONFIG from "../../config/config";

const CustomButton = (props) => {
  const backgroundColor = props.backgroundColor || "#b50829";
  const title = props.title || "khan";
  const textColor = props.textColor || "white";
  const onClick = props.onClick;

  return (
    <Row className="custom_button_container">
      <button
        onClick={onClick}
        style={{
          ...props.style,
          backgroundColor: backgroundColor,
          color: textColor,
          width:
            CONFIG.typeScreen === "xs" || CONFIG.typeScreen === "sm"
              ? "100%"
              : "auto",
          // padding: "5px",
          borderRadius: "3px",
        }}
      >
        {title}
      </button>
    </Row>
  );
};

export default CustomButton;
