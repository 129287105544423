import axios from "axios";
import CONFIG from "../config/config";

export const getMemberAPI = () => {
  return axios.get(
    `${CONFIG.url}/getPartnerMember?p_admin_user_id=${CONFIG.admin_user_id}`
  );
};

export const updateInfoUserAPI = (body) => {
  return axios.put(`${CONFIG.url}/updateInfoUser`, {
    p_password: body.p_password,
    p_full_name: body.p_full_name,
    p_mobile: body.p_mobile,
    p_identity: body.p_identity,
    p_email: body.p_email,
    p_admin_user_id: body.p_admin_user_id,
  });
};
