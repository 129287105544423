import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducers from "./reducers/index";
import rootSaga from "./sagas/index";
//sagas
import createSagaMiddleware from "redux-saga";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];
function configureStore() {
  const store = createStore(
    rootReducers,
    composeWithDevTools(applyMiddleware(...middleware)),
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );

  sagaMiddleware.run(rootSaga);

  return store;
}
const store = configureStore();
export default store;
