import axios from "axios";
import CONFIG from "../config/config";

export const organizationAPI = () => {
  return axios.get(
    `${CONFIG.url}/getOrganization?partner_id=${CONFIG.organize.partner_id}&organization_id=${CONFIG.organize.organization_id}&role=${CONFIG.role}`
  );
};

export const getOrganC3API = () => {
  return axios.get(
    `${CONFIG.url}/getOrganC3?organization_id=${CONFIG.organize.organization_id}`
  );
};
