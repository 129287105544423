import React, { useState, useEffect, useCallback } from "react";
import { toast, ToastContainer } from "react-toastify";
import Loading from "../components/Loading";
import { Form, Button, Modal } from "react-bootstrap";
import axios from "axios";
import API from "../service/api";
import Adapter from "../service/adapter";
const token = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoic3VwZXJ1c2VyIiwidXNlcl9pZCI6MCwiaWF0IjoxNjI2NjY4OTcyfQ.-5OjOPa6MezmjwrtQkvJidh8fogoPZ9NC9HHz7yTAdU`;
const config = {
  headers: { Authorization: `Bearer ${token}` },
};
const Notification = (props) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [options, setOptions] = useState(1);
  const [pushDefault, setPushDefault] = useState({
    user_ids: [],
    title: "",
    content: "",
    error: "",
    metadata: {
      notification_type: 4,
      uri: "",
    },
    options: {
      needToPush: true,
    },
  });

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShow(!show);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const onClose = useCallback(() => {
    setShow(!show);
  }, [show]);

  const pushNotification = async (e) => {
    e.preventDefault();

    if(!pushDefault.title){
      setPushDefault({...pushDefault, error: "Title không bỏ trống"});
      setShow(true);
      return;
    }
    if(!pushDefault.content){
      setPushDefault({...pushDefault, error: "Content không bỏ trống"});
      setShow(true);
      return;
    }
    if(pushDefault.content.length >= 255){
      setPushDefault({...pushDefault, error: `Content quá dài. Hiện đang là ${pushDefault.content.length} ký tự`});
      setShow(true);
      return;
    }
    if(!pushDefault.metadata.uri){
      setPushDefault({...pushDefault, error: "Url không bỏ trống"});
      setShow(true);
      return;
    }
    setIsLoading(true);

    await API.get(`${Adapter.get_users.url}`)
      .then((res) => {
        if (res.data.success) {
          pushDefault.user_ids = res.data.data.usersID;
          setPushDefault(pushDefault);
          axios
            .post(`https://api.i-hr.vn/v1/notifications/`, pushDefault, config)
            .then(function (response) {
              if (response.data.success) {
                toast.success("Push notification success!");
                let old = JSON.parse(JSON.stringify(pushDefault));
                old.title = "";
                old.content = "";
                old.metadata.uri = "";
                setPushDefault(old);
              }
            })
            .catch(function (error) {});
        }
      })
      .catch((err) => {});

    setIsLoading(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    let old = JSON.parse(JSON.stringify(pushDefault));
    switch (name) {
      case "uri":
        old.metadata.uri = value;
        break;
      default:
        old[name] = value;
        break;
    }
    setPushDefault(old);
  };
  return (
    <div>
      <h2 className="page-header">Push Notification</h2>
      <Modal show={show} onHide={onClose}>
        <Modal.Title
          id="example-custom-modal-styling-title"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {pushDefault.error}
        </Modal.Title>
      </Modal>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card__body">
              <Form onSubmit={pushNotification}>
                <Form.Group className="mb-3" controlId={"user"}>
                  <Form.Label>Users</Form.Label>
                  <Form.Select
                    aria-label="Options"
                    onChange={(e) => {
                      setOptions(e.target.value);
                    }}
                  >
                    <option value="1">Tất cả người dùng</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId={"title"}>
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={pushDefault.title}
                    type="text"
                    name={"title"}
                    placeholder="Nhập vào title của thông báo"
                    aria-required={"true"}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId={"content"}>
                  <Form.Label>Nội dung</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={pushDefault.content}
                    type="text"
                    name={"content"}
                    placeholder="Nhập vào nội dung của thông báo"
                    aria-required={"true"}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId={"uri"}>
                  <Form.Label>Url</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={pushDefault.metadata.uri}
                    type="text"
                    name={"uri"}
                    placeholder="Nhập vào url của thông báo"
                    aria-required={"true"}
                  />
                </Form.Group>
                <Button variant="primary" type="submit">
                  Gửi
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Loading isLoading={isLoading} />
    </div>
  );
};

export default Notification;
