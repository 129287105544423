import React, { useEffect, useMemo, useCallback } from "react";
import "./Member.css";
import { useDispatch, useSelector } from "react-redux";
import { getMemberRequest } from "../../redux/actions/member.action";
import Table from "../../components/table/Table";
import CustomButton from "../../components/common/button";
import ExportExcel from "../../components/common/ExportExcel";
import moment from "moment";

const Member = () => {
  const dispatch = useDispatch();
  const getMemberStore = useSelector((state) => state.member);

  const corporateTableHead = useMemo(
    () => ["", "Mã giới thiệu", "Họ và tên", "Cơ quan", "Điện thoại"],
    []
  );

  const renderHead = useCallback(
    (item, index) => (
      <th style={{ border: "1px solid #ccc" }} key={index}>
        {item}
      </th>
    ),
    []
  );

  const renderBody = useCallback(
    (item, index) => (
      <tr key={index}>
        <td style={{ border: "1px solid #ccc", textAlign: "center" }}>
          {index + 1}
        </td>
        <td style={{ border: "1px solid #ccc" }}>{item.ref_code}</td>
        <td style={{ border: "1px solid #ccc" }}>{item.full_name}</td>
        <td style={{ border: "1px solid #ccc" }}>{item.company}</td>
        <td style={{ border: "1px solid #ccc" }}>{item.mobile}</td>
      </tr>
    ),
    []
  );

  useEffect(() => {
    dispatch(getMemberRequest());
    // setMembers(getMemberStore.member);
  }, [dispatch]);

  const exportToExcel = async () => {
    let arrayExcel = [];
    getMemberStore.member.forEach((item) =>
      arrayExcel.push({
        "Mã giới thiệu": item.ref_code,
        "Họ và tên": item.full_name,
        "Cơ quan": item.company,
        "Số điện thoại": item.mobile,
      })
    );
    let getMonth = moment(new Date(), moment.ISO_8601).format("MM-YYYY");
    ExportExcel(arrayExcel, `iHrThanhVien_Thang${getMonth}`);
  };

  return (
    <div className="member_container">
      <p className="title_component">Thành Viên</p>

      <div className="content_member">
        <div className="member_top">
          <div className="content_top">
            <div className="content__top">
              <span className="content">Tổng:</span>
              <span className="content_number">
                {getMemberStore.member.length | 0}
              </span>
            </div>

            <div className="content__excel">
              <CustomButton
                title="Xuất ra Excel"
                backgroundColor="#fafafb"
                textColor="#B50829"
                onClick={exportToExcel}
              />
            </div>
          </div>
        </div>

        <div className="card__body" style={{ marginTop: 20 }}>
          {getMemberStore.member && getMemberStore.member.length !== 0 ? (
            <Table
              limit="50"
              headData={corporateTableHead}
              renderHead={(item, index) => renderHead(item, index)}
              bodyData={getMemberStore.member}
              renderBody={(item, index) => renderBody(item, index)}
              count={0}
            />
          ) : (
            <h2 style={{ marginTop: "30px", textAlign: "center" }}>
              Hiện không có thông tin
            </h2>
          )}
        </div>
      </div>
    </div>
  );
};

export default Member;
