import * as types from "../type";

export const otpRequest = (payload) => {
  return {
    type: types.GET_OTP_REQUEST,
    payload: payload,
  };
};

export const otpSuccess = (payload) => {
  return {
    type: types.GET_OTP_SUCCESS,
    payload: payload,
  };
};

export const otpError = (payload) => {
  return {
    type: types.GET_OTP_ERROR,
    payload: payload,
  };
};

export const otp_verify_Request = (payload) => {
    return {
      type: types.VERIFY_OTP_REQUEST,
      payload: payload,
    };
  };
  
  export const otp_verify_Success = (payload) => {
    return {
      type: types.VERIFY_OTP_SUCCESS,
      payload: payload,
    };
  };
  
  export const otp_verify_Error = (payload) => {
    return {
      type: types.VERIFY_OTP_ERROR,
      payload: payload,
    };
  };
  
