import * as types from "../type";

export const revenueRequest = (payload) => {
  return {
    type: types.REVENUE_REQUEST,
    payload: payload,
  };
};

export const revenueSuccess = (payload) => {
  return {
    type: types.REVENUE_SUCCESS,
    payload: payload,
  };
};

export const revenueError = (payload) => {
  return {
    type: types.REVENUE_ERROR,
    payload: payload,
  };
};
