import * as types from "../type";

export const reportRequest = (payload) => {
  return {
    type: types.REPORT_REQUEST,
    payload: payload,
  };
};

export const reportSuccess = (payload) => {
  return {
    type: types.REPORT_SUCCESS,
    payload: payload,
  };
};

export const reportError = (payload) => {
  return {
    type: types.REPORT_ERROR,
    payload: payload,
  };
};

export const getBranchRequest = (payload) => {
    return {
      type: types.GET_BRANCH_REQUEST,
      payload: payload,
    };
  };
  
  export const getBranchSuccess = (payload) => {
    return {
      type: types.GET_BRANCH_SUCCESS,
      payload: payload,
    };
  };
  
  export const getBranchError = (payload) => {
    return {
      type: types.GET_BRANCH_ERROR,
      payload: payload,
    };
  };

  export const reportSaleRequest = (payload) => {
    return {
      type: types.REPORT_SALE_REQUEST,
      payload: payload,
    };
  };
  
  export const reportSaleSuccess = (payload) => {
    return {
      type: types.REPORT_SALE_SUCCESS,
      payload: payload,
    };
  };
  
  export const reportSaleError = (payload) => {
    return {
      type: types.REPORT_SALE_ERROR,
      payload: payload,
    };
  };