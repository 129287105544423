import * as types from "../../type";
import { takeLatest, call, put } from "redux-saga/effects";
import { getMemberAPI } from "../../../api/member.api";
import { getMemberSuccess, getMemberError } from "../../actions/member.action";

function* getMember(action) {
  try {
    const submit = yield call(getMemberAPI, action.payload);
    console.log(submit);
    yield put(getMemberSuccess(submit));
  } catch (err) {
    yield put(getMemberError(err));
  }
}

export default function* getMemberSaga() {
  yield takeLatest(types.GET_MEMBER_REQUEST, getMember);
}
