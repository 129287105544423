import * as types from "../../type";
import { takeLatest, call, put } from "redux-saga/effects";
import { customerAPI, customerTotalAPI } from "../../../api/customer.api";
import {
	listCustomerJHLSuccess,
	listCustomerJHLError,
} from "../../actions/listCustomerJHL";

function* customer(action) {
	try {
		action.payload.limit = 50;
		const submit = yield call(customerAPI, action.payload);

		let listCustomerTotal = 0;

		const customerResponse = yield call(customerTotalAPI, action.payload);
		if (customerResponse)
			listCustomerTotal = customerResponse.data.data.customerTotalJHL;

		yield put(listCustomerJHLSuccess({ submit, listCustomerTotal }));
	} catch (err) {
		yield put(listCustomerJHLError(err));
	}
}

export default function* customerSaga() {
	yield takeLatest(types.GET_LIST_CUSTOMER_JHL_REQUEST, customer);
}
