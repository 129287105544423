import * as types from "../../type";
import { takeLatest, call, put } from "redux-saga/effects";
import { organizationSuccess, organizationError } from "../../actions/organization.action";
import { organizationAPI } from "../../../api/organization.api";

function* organization(action) {
  try {
    const submit = yield call(organizationAPI, action.payload);
    yield put(organizationSuccess(submit));
  } catch (err) {
    yield put(organizationError(err));
  }
}

export default function* organizationSaga() {
  yield takeLatest(types.GET_ORGANIZATION_REQUEST, organization);
}
