import * as types from "../type";

const initialState = {
	loading: false,
	getBranch: [],
	getActivityReport: [],
	getSaleReport: [],
	activity_total: 0,
	app_total: 0,
	corporate_total: 0,
};

const ReportReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.REPORT_REQUEST:
			return {
				...state,
				loading: true,
			};
		case types.REPORT_SUCCESS:
			return {
				...state,
				loading: false,
				getActivityReport: action.payload.submit.data.data.getActivityReport,
				activity_total: action.payload.activity_total,
				app_total: action.payload.app_total,
				corporate_total: action.payload.corporate_total,
			};

		case types.REPORT_ERROR:
			return {
				...state,
				loading: false,
			};

		case types.GET_BRANCH_REQUEST:
			return {
				...state,
				loading: true,
			};

		case types.GET_BRANCH_SUCCESS:
			return {
				...state,
				loading: true,
				getBranch: action.payload.data.data.getBranchControl,
			};

		case types.GET_BRANCH_ERROR:
			return {
				...state,
				loading: true,
			};

		//SALE
		case types.REPORT_SALE_REQUEST:
			return {
				...state,
				loading: true,
			};
		case types.REPORT_SALE_SUCCESS:
			return {
				...state,
				loading: false,
				getSaleReport: action.payload.data.data.getSaleReport,
			};

		case types.REPORT_SALE_ERROR:
			return {
				...state,
				loading: false,
			};

		//REPORT_ERROR
		default:
			return {
				...state,
				loading: false,
			};
	}
};

export default ReportReducer;
