import React, { useRef } from "react";

import "./dropdown.css";
import { useDispatch } from "react-redux";
import { logOutRequest } from "../../redux/actions/authen.action";

const clickOutsideRef = (content_ref, toggle_ref) => {
  document.addEventListener("mousedown", (e) => {
    // user click toggle
    if (toggle_ref.current && toggle_ref.current.contains(e.target)) {
      content_ref.current.classList.toggle("active");
    } else {
      // user click outside toggle and content
      if (content_ref.current && !content_ref.current.contains(e.target)) {
        content_ref.current.classList.remove("active");
      }
    }
  });
};

const Dropdown = (props) => {
  const dropdown_toggle_el = useRef(null);
  const dropdown_content_el = useRef(null);

  clickOutsideRef(dropdown_content_el, dropdown_toggle_el);

  const dispatch = useDispatch();
  return (
    <>
      {props.isCustomDropDown ? (
        <div className={props.isExtend ? "" : "view_small_dropdown"}>
          {props.isRequire ? (
            <div>
              <label className="title">{props.title}</label>
              <label className="title_require">*</label>
            </div>
          ) : (
            <label className="title">{props.title}</label>
          )}

          <select
            defaultValue={"sex01"}
            onChange={(e) => {
              //   setChooseVIP(e.target.value);
              //   if (e.target.value === VIP_Package.NORMAL) setCheckHotRec(false);
            }}
            className={
              props.isExtend ? "dropdown_custom_extend" : "dropdown_custom"
            }
          >
            <option value={"sex01"}>Nam</option>

            <option value={"sex02"}>Nữ</option>
          </select>
        </div>
      ) : (
        <div className="dropdown">
          <button
            // ref={dropdown_toggle_el}
            onClick={() => {
              dispatch(logOutRequest());
            }}
            className="dropdown__toggle"
          >
            {props.icon ? <i className={props.icon}></i> : ""}
            {props.badge ? (
              <span className="dropdown__toggle-badge">{props.badge}</span>
            ) : (
              ""
            )}
            {props.customToggle ? props.customToggle() : ""}
          </button>
          <div ref={dropdown_content_el} className="dropdown__content">
            {props.contentData && props.renderItems
              ? props.contentData.map((item, index) =>
                  props.renderItems(item, index)
                )
              : ""}
            {props.renderFooter ? (
              <div className="dropdown__footer">{props.renderFooter()}</div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Dropdown;
