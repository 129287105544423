import * as types from "../../type";
import { takeLeading, call, put, all } from "redux-saga/effects";
import {
  otp_verify_Success,
  otp_verify_Error,
  otpSuccess,
  otpError,
} from "../../actions/otp.action";
import { OTP_verifyAPI, OTP_requestAPI } from "../../../api/otp.api";

function* OTP_Request_Saga(action) {
  try {
    const submit = yield call(OTP_requestAPI(action.payload));
    yield put(otpSuccess(submit));
  } catch (err) {
    yield put(otpError(err));
  }
}

function* OTP_Verify_Saga(action) {
  try {
    const response = yield call(OTP_verifyAPI(action.payload));
    yield put(otp_verify_Success(response));
  } catch (err) {
    yield put(otp_verify_Error(err));
  }
}

export default function* OTPSaga() {
  yield all([
    takeLeading(types.GET_OTP_REQUEST, OTP_Request_Saga),
    takeLeading(types.VERIFY_OTP_REQUEST, OTP_Verify_Saga),
  ]);
}
