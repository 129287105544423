import * as types from "../type";

const initialState = {
  loading: false,
  verify_otp_success: false,
};

const OTPReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_OTP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case types.VERIFY_OTP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.VERIFY_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        verify_otp_success: true,
      };

    case types.VERIFY_OTP_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return {
        ...state,
        loading: false,
      };
  }
};

export default OTPReducer;
