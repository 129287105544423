import React from "react";
import "./KYC.css";

const KYC = () => {
  return (
    <div className="KYC_container">
      <h2>Xác thực người dùng</h2>
      <div className="search__KYC">
        <input type="text" className="input_search__KYC" />
        <select class="choice" name="choice" id="choice">
          <option value="">Ngày Nộp</option>
          <option value="ha-giang">Chưa duyệt</option>
          <option value="cao-bang">Đã duyệt</option>

        </select>
        <p type="Submit" className="Submit__KYC">Tìm Kiếm</p>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th scope="col">Ngày nộp</th>
            <th scope="col">User ID</th>
            <th scope="col">Ảnh</th>
            <th scope="col">Họ và tên</th>
            <th scope="col">Điện thoại</th>
            <th scope="col">Email</th>
            <th scope="col">Trạng thái</th>
            <th scope="col">Thao tác</th>
          </tr>
        </thead>
        
      </table>      <div class="table__pagination"><div class="table__pagination-item active">1</div><div class="table__pagination-item ">2</div><div class="table__pagination-item ">3</div><div class="table__pagination-item ">4</div></div>
    </div>
  );
};

export default KYC;
