import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";

import "./assets/boxicons-2.0.7/css/boxicons.min.css";
import "./assets/css/grid.css";
import "./assets/css/theme.css";
import "./assets/css/index.css";

import Layout from "./components/layout/Layout";
import "bootstrap/dist/css/bootstrap.min.css";
import store from "./redux/store";
// import { BrowserRouter, Route, Switch } from "react-router-dom";
// import RegisterCTV from "./components/register/RegisterCTV";

document.title = "i-hr CRM";
ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Layout />
      {/* <BrowserRouter>
        <Switch>
          <Route path='/' component={Layout}/>
          <Route path='/register' component={RegisterCTV}/>
        </Switch>
      </BrowserRouter> */}
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
