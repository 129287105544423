import React from "react";

import { Link } from "react-router-dom";

import "./sidebar.css";

import logo from "../../assets/images/logo-login.png";

import sidebar_items from "../../assets/JsonData/sidebar_routes.json";
import CONFIG from "../../config/config";
import { useDispatch } from "react-redux";
import { logOutRequest } from "../../redux/actions/authen.action";

const SidebarItem = (props) => {
  const active = props.active ? "active" : "";
  const dispatch = useDispatch();
  const logOut = () => {
    if (props.logout) {
      dispatch(logOutRequest());
    }
  };

  return (
    <div onClick={logOut} className="sidebar__item">
      <div className={`sidebar__item-inner ${active}`}>
        <i className={props.icon}></i>
        <span>{props.title}</span>
      </div>
    </div>
  );
};

// {
//     "display_name": "Notification ",
//     "route": "/notification",
//     "icon": "bx bxs-user-detail"
// },
const Sidebar = (props) => {
  const activeItem = sidebar_items.findIndex(
    (item) => item.route === props.location.pathname
  );

  return (
    <div className="sidebar" style={{overflow: "auto" }}>
      <div className="sidebar__logo">
        <img src={logo} alt="company logo" />
        <span className="logo_company">Admin</span>
      </div>
      {sidebar_items.map((item, index) => {
        if (!item.route) {
          return (
            <div key={index} className="sidebar__group">
              <p>{item.display_name}</p>
            </div>
          );
        } else {
          return (
            <Link to={item.route} key={index}>
              {CONFIG.role === 1 ? (
                <SidebarItem
                  title={item.display_name}
                  icon={item.icon}
                  active={index === activeItem}
                  logout={index === sidebar_items.length - 2}
                />
              ) : (
                <SidebarItem
                  title={item.display_name}
                  icon={item.icon}
                  active={index === activeItem}
                  logout={index === sidebar_items.length - 2}
                  // TempNotify={index === 5}
                />
              )}
            </Link>
          );
        }
      })}
      {/* <SidebarItem
        title={"Đăng xuất"}
        icon={"bx bx-log-out"}
        active={"/" === activeItem}
        logout={true}
      />
      <SidebarItem
        title={"Hỗ trợ"}
        icon={"bx bxs-help-circle"}
        active={"/support" === activeItem}
      /> */}
    </div>
  );
};

// {
//   "display_name": "Notification ",
//   "route": "/notification",
//   "icon": "bx bxs-user-detail"
// },
// {
//   "display_name": "Doanh Số",
//   "route": "/sales",
//   "icon": "bx bxs-dollar-circle"
// },
// {
//   "display_name": "Đăng xuất",
//   "route": "/",
//   "icon": "bx bx-log-out"
// },
// {
//   "display_name": "Hỗ trợ",
//   "route": "/support",
//   "icon": "bx bxs-help-circle"
// }
export default Sidebar;
