import * as types from "../../type";
import { takeLatest, call, put } from "redux-saga/effects";
import { revenueAPI } from "../../../api/reventue.api";
import { revenueSuccess, revenueError } from "../../actions/revenue.action";

function* revenueSaga(action) {
  try {
    const submit = yield call(revenueAPI, action.payload);
    yield put(revenueSuccess(submit));
  } catch (err) {
    yield put(revenueError(err));
  }
}

export default function* RevenueSaga() {
  yield takeLatest(types.REVENUE_REQUEST, revenueSaga);
}
