import React, { useState } from "react";
import "./ChangePassword.css";
import CONFIG from "../../config/config";
import MD5 from "crypto-js/md5";

const ChangePassword = () => {
  const [actions, setActions] = useState({
    typePassWord1: "password",
    typePassWord2: "password",
    typePassWord3: "password",
    reTypePassWord: true,
    confirmNewPassWord: true,
    newPassWord: "",
    ReTypeNewPassWord: "",
    validateNewPassWord: "",
    validateConfirmNewPassWord: "",
  });

  const md5 = (e) => {
    var data = MD5(e).toString();
    return data;
  };

  return (
    <div className="ChangePassword_container">
      <h1>Đổi mật khẩu</h1>
      <div className="changpass_username">
        <span className="title_username">Tài khoản :</span>
        <span className="username">{CONFIG.username}</span>
      </div>
      <div className="changePass_content">
        <div className="title_content">
          <p className="title__content">Đổi mật khẩu</p>
          <div className="changepass__input">
            <div className="changepass_input">
              <span className="title_input">
                Xác nhận hiện tại <span className="star">*</span>
              </span>
              <input
                type={actions.typePassWord1}
                className=" input_password"
                onChange={(e) => {
                  // setPassWord(e.target.value);
                  setActions({
                    ...actions,
                    reTypePassWord: md5(e.target.value) === CONFIG.password,
                  });
                }}
              />
              <i
                className="bx bx-show"
                onClick={() => {
                  setActions({
                    ...actions,
                    typePassWord1:
                      actions.typePassWord1 === "password"
                        ? "text"
                        : "password",
                  });
                }}
              />
              {!actions.reTypePassWord ? (
                <span className="valid_password">
                  Xác nhận mật khẩu không khớp
                </span>
              ) : (
                <span className="valid_password">&ensp;</span>
              )}
            </div>

            <div className="changepass_input">
              <span className="title_input">
                Mật khẩu mới <span className="star">*</span>
              </span>
              <input
                type={actions.typePassWord2}
                className=" input_password"
                onChange={(e) => {
                  if (e.target.value.length < 6) {
                    setActions({
                      ...actions,
                      validateNewPassWord: "Mật khẩu phải lớn hơn 6 ký tự",
                    });
                  } else {
                    setActions({
                      ...actions,
                      newPassWord: e.target.value,
                      validateNewPassWord: "",
                    });
                  }
                }}
              />
              <i
                className="bx bx-show"
                onClick={() => {
                  setActions({
                    ...actions,
                    typePassWord2:
                      actions.typePassWord2 === "password"
                        ? "text"
                        : "password",
                  });
                }}
              />
              {actions.validateNewPassWord ? (
                <span className="valid_password">
                  {actions.validateNewPassWord}
                </span>
              ) : (
                <span className="valid_password">&ensp;</span>
              )}
            </div>
            <div className="changepass_input">
              <span className="title_input">
                Xác nhận nhận mật khẩu mới <span className="star">*</span>
              </span>
              <input
                type={actions.typePassWord3}
                className=" input_password"
                onChange={(e) => {
                  if (e.target.value !== actions.newPassWord) {
                    setActions({
                      ...actions,
                      validateConfirmNewPassWord:
                        "Xác nhận mật khẩu không khớp",
                    });
                  } else {
                    setActions({
                      ...actions,
                      confirmNewPassWord: e.target.value,
                      validateConfirmNewPassWord: "",
                    });
                  }
                }}
              />
              <i
                className="bx bx-show"
                onClick={() => {
                  setActions({
                    ...actions,
                    typePassWord3:
                      actions.typePassWord3 === "password"
                        ? "text"
                        : "password",
                  });
                }}
              />
              {actions.validateConfirmNewPassWord ? (
                <span className="valid_password">
                  {actions.validateConfirmNewPassWord}
                </span>
              ) : (
                <span className="valid_password">&ensp;</span>
              )}
            </div>
          </div>
          <div className="changepassword_submit">
            <p className="text_submit">Lưu Lại</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
