import * as types from "../../type";
import { takeLatest, call, put } from "redux-saga/effects";
import { updateInfoUserAPI } from "../../../api/member.api";
import {
  updateInfoUserSuccess,
  updateInfoUserError,
} from "../../actions/member.action";
import CONFIG from "../../../config/config";
import MD5 from "crypto-js/md5";

function* updateInfoUser(action) {
  try {
    const infoUser = JSON.parse(localStorage.getItem("infoUser"));
    localStorage.removeItem("infoUser");
    const submit = yield call(updateInfoUserAPI, action.payload);
    var data = {
      admin_user_id: action.payload.p_admin_user_id,
      company: CONFIG.company,
      createdAt: infoUser.createdAt,
      created_at: infoUser.created_at,
      district_code: infoUser.district_code,
      email: action.payload.p_email,
      fullName: action.payload.p_full_name,
      gender: infoUser.gender,
      identity: action.payload.p_identity,
      is_deleted: infoUser.is_deleted,
      mobile: action.payload.p_mobile,
      organization_id: infoUser.organization_id,
      parent_id: infoUser.parent_id,
      parent_ref_code: infoUser.parent_ref_code,
      password: MD5(action.payload.p_password).toString(),
      province_code: infoUser.province_code,
      ref_code: infoUser.ref_code,
      role: infoUser.role,
      username: infoUser.username,
    };
    CONFIG.password = MD5(action.payload.p_password).toString();
    localStorage.setItem("infoUser", JSON.stringify(data));
    yield put(updateInfoUserSuccess(submit));
  } catch (err) {
    yield put(updateInfoUserError(err));
  }
}

export default function* updateInfoUserSaga() {
  yield takeLatest(types.UPDATE_INFO_USER_REQUEST, updateInfoUser);
}
