import * as types from "../type";

export const listCustomerJHLRequest = (payload) => {
  return {
    type: types.GET_LIST_CUSTOMER_JHL_REQUEST,
    payload: payload,
  };
};

export const listCustomerJHLSuccess = (payload) => {
  return {
    type: types.GET_LIST_CUSTOMER_JHL_SUCCESS,
    payload: payload,
  };
};

export const listCustomerJHLError = (payload) => {
  return {
    type: types.GET_LIST_CUSTOMER_JHL_ERROR,
    payload: payload,
  };
};
