import * as types from "../type";

const initialState = {
	loading: false,
	listCustomer: [],
	listCustomerTotal: 0,
};

const ListCustomerJHLReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.GET_LIST_CUSTOMER_JHL_REQUEST:
			return {
				...state,
				loading: true,
			};
		case types.GET_LIST_CUSTOMER_JHL_SUCCESS:
			return {
				...state,
				loading: false,
				listCustomer: action.payload.submit.data.data.executeSQL[0],
				listCustomerTotal: action.payload.listCustomerTotal,
			};
		default:
			return {
				...state,
				loading: false,
			};
	}
};

export default ListCustomerJHLReducer;
