import React from "react";
import "./datePicker.css";
import { Col } from "react-bootstrap";
import moment from "moment";

const datePicker = (props) => {
  //props.type = 0 -> null . ko co title
  //props.type = 1 -> row . title hàng
  //props.type = 2 -> null . title cột
  return (
    <Col
      xl={props.xl}
      lg={props.lg}
      xs={12}
      md={props.md}
      style={{ ...props.style, marginTop: props.top || 0 }}
    >
      {props.typeDatePicker === "month" ? (
        <input
          type="month"
          className="datePicker_container"
          value={moment(props.currentDate, moment.ISO_8601).format(
            "YYYY-MM"
          )}
          onChange={(e) => {
            props.valueDatePicker(e.target.value);
          }}
        />
      ) : (
        <input
          type="date"
          className="datePicker_container"
          value={moment(props.currentDate, moment.ISO_8601).format(
            "YYYY-MM-DD"
          )}
          onChange={(e) => {
            props.valueDatePicker(e.target.value);
          }}
        />
      )}
    </Col>
  );
};

export default datePicker;
