const Adapter = {
  new_corporates: {
    url: `/manager/new-corporates`,
    method: "GET",
  },
  new_jobs: {
    url: "/manager/new-jobs",
    method: "GET",
    params: {
      skip: null,
      limit: null,
    },
  },
  update_corporate: {
    url: "/manager/update-corporate",
    method: "PUT",
    params: {
      corporate_code: null,
    },
  },
  update_job: {
    url: "/manager/update-job",
    method: "PUT",
    params: {
      job_id: null,
    },
  },
  get_users: {
    url: "/manager/get-users",
    method: "GET",
    params: {
      options: null,
    },
  },
};
export default Adapter;
