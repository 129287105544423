import * as types from "../../type";
import { takeLatest, call, put } from "redux-saga/effects";
import {
	getAdminActivityReportAPi,
	getAdminActivityReportTotalAPI,
} from "../../../api/report.api";
import {
	getAdminActivityReportTotalAppAPI,
	getAdminActivityReportTotalCorpAPI,
} from "../../../api/report.api";
import { reportSuccess, reportError } from "../../actions/report.action";

function* getActivityReportSaga(action) {
	try {
		action.payload.limit = 50;
		const submit = yield call(getAdminActivityReportAPi, action.payload);
		//const response = submit.data.data.getActivityReport;

		let app_total = 0;
		let activity_total = 0;
		let corporate_total = 0;

		const activityResponse = yield call(
			getAdminActivityReportTotalAPI,
			action.payload
		);
		if (activityResponse)
			activity_total = activityResponse.data.data.totalActivity;

		const response = yield call(
			getAdminActivityReportTotalAppAPI,
			action.payload
		);
		if (response) app_total = response.data.data.totalApp;

		const responseCorp = yield call(
			getAdminActivityReportTotalCorpAPI,
			action.payload
		);
		if (responseCorp) corporate_total = responseCorp.data.data.totalCorp;

		// if (response && response.length) {
		//   response.forEach((element) => {
		//     app_total += parseInt(element.app_total);
		//     corporate_total += parseInt(element.corporate_total);
		//   });
		// }

		const payload = {
			submit,
			activity_total,
			app_total,
			corporate_total,
		};
		yield put(reportSuccess(payload));
	} catch (err) {
		yield put(reportError(err));
	}
}

export default function* ReportSaga() {
	yield takeLatest(types.REPORT_REQUEST, getActivityReportSaga);
}
