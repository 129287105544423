import * as types from "../../type";
import { takeLatest, call, put } from "redux-saga/effects";
import { getSaleReportAPi } from "../../../api/report.api";
import { reportSaleError, reportSaleSuccess } from "../../actions/report.action";

function* getSaleReportSaga(action) {
  try {
    const submit = yield call(getSaleReportAPi, action.payload);
    yield put(reportSaleSuccess(submit));
  } catch (err) {
    yield put(reportSaleError(err));
  }
}

export default function* ReportSaleSaga() {
  yield takeLatest(types.REPORT_SALE_REQUEST, getSaleReportSaga);
}
