import React, { useCallback, useState, useMemo, useEffect } from "react";
import { Container, Form, Row, Col } from "react-bootstrap";
import CONFIG from "../../config/config";
import { ToastContainer } from "react-toastify";
import Loading from "../../components/Loading";
import Table from "../../components/table/Table";
import DatePicker from "../../components/common/datePicker";
import "./Sale.css";
import CustomButton from "../../components/common/button";
import { useDispatch, useSelector } from "react-redux";
import money_format from "../../config/moneyFormat";
import ExportExcel from "../../components/common/ExportExcel";
import { reportSaleRequest } from "../../redux/actions/report.action";
import API from "../../service/api";
import moment from "moment";

const Sale = () => {
  const dispatch = useDispatch();
  const [dataGetBranch, setDataGetBranch] = useState([]);
  const [branchType, setBranchType] = useState(0);
  const [dateToReport, setDateToReport] = useState(
    moment(new Date(), moment.ISO_8601).format("YYYY-MM")
  );
  const [titleToAPI, setTitleToAPI] = useState("");

  const reportStore = useSelector((state) => state.report);

  useEffect(() => {
    API.get(
      `${CONFIG.url}/getBranch?p_admin_user_id=${CONFIG.admin_user_id}`
    ).then((response) => {
      setDataGetBranch(response.data.data.getBranchControl);
      const data = {
        p_organization_id: 0,
        p_from_date: moment(new Date(), moment.ISO_8601)
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00"),
        p_to_date: moment(new Date(), moment.ISO_8601)
          .endOf("month")
          .format("YYYY-MM-DD 23:59:59"),
        p_title: "",
      };
      dispatch(reportSaleRequest(data));
    });
  }, [dispatch]);

  const corporateTableHead = useMemo(
    () => [
      "",
      "Thời gian",
      "Mã đơn",
      "Mã giới thiệu",
      "Đơn vị triển khai",
      "Doanh nghiệp",
      "Nội dung",
      "Chi tiêu (VND)",
      "Hoa hồng",
      "Doanh thu (VND)",
    ],
    []
  );

  const renderHead = useCallback(
    (item, index) => (
      <th style={{ border: "1px solid #ccc" }} key={index}>
        {item}
      </th>
    ),
    []
  );
  //.replace("T", " ").replace(".000Z", "")
  const renderBody = useCallback(
    (item, index) => (
      <tr key={index}>
        <td style={{ border: "1px solid #ccc", textAlign: "center" }}>
          {index + 1}
        </td>
        <td style={{ border: "1px solid #ccc" }}>
          {moment(
            item.created_at.replace("T", " ").replace(".000Z", ""),
            moment.ISO_8601
          ).format("DD-MM-YYYY hh:mm:ss")}
        </td>
        <td style={{ border: "1px solid #ccc", textAlign: "center" }}>
          {item.admin_user_tran_id}
        </td>
        <td style={{ border: "1px solid #ccc", textAlign: "center" }}>
          {item.ref_code}
        </td>
        <td style={{ border: "1px solid #ccc" }}>{item.org_name}</td>
        <td style={{ border: "1px solid #ccc" }}>{item.corporate_name}</td>
        <td style={{ border: "1px solid #ccc" }}>{item.news_name}</td>
        <td style={{ border: "1px solid #ccc", textAlign: "center" }}>
          {money_format(item.amount * 1000)}
        </td>
        <td style={{ border: "1px solid #ccc", textAlign: "center" }}>
          {!item.percent || item.percent === null ? 0 : item.percent}%
        </td>
        <td style={{ border: "1px solid #ccc", textAlign: "center" }}>
          {money_format(
            parseInt(item.income ? item.income.split(".") : 0) * 1000
          )}
        </td>
      </tr>
    ),
    []
  );

  const changeDate = useCallback(
    (e) => {
      setDateToReport(e);
      setTimeout(() => {
        const data = {
          p_organization_id: branchType,
          p_from_date: moment(e, moment.ISO_8601)
            .startOf("month")
            .format("YYYY-MM-DD 00:00:00"),
          p_to_date: moment(e, moment.ISO_8601)
            .endOf("month")
            .format("YYYY-MM-DD 23:59:59"),
          p_title: titleToAPI,
        };
        dispatch(reportSaleRequest(data));
      }, 1000);
    },
    [branchType, dispatch, titleToAPI]
  );

  const onChangeTypeBranch = (value, branch) => {
    const data = {
      p_organization_id: branch,
      p_from_date: moment(dateToReport, moment.ISO_8601)
        .startOf("month")
        .format("YYYY-MM-DD 00:00:00"),
      p_to_date: moment(dateToReport, moment.ISO_8601)
        .endOf("month")
        .format("YYYY-MM-DD 23:59:59"),
      p_title: value,
    };
    dispatch(reportSaleRequest(data));
  };

  const exportToExcel = async () => {
    var arrayExcel = [];
    await reportStore.getSaleReport.map((item) =>
      arrayExcel.push({
        "Thời gian": item.created_at.replace("T", " ").replace(".000Z", ""),
        "Mã đơn": item.admin_user_tran_id,
        "Mã giới thiệu": item.ref_code,
        "Đơn vị triển khai": item.org_name,
        "Doanh nghiệp": item.corporate_name,
        "Nội dung": item.news_name,
        "Chi tiêu": money_format(item.amount * 1000),
        "Hoa hồng":
          !item.percent || item.percent === null ? 0 : item.percent + "%",
        "Doanh thu": money_format(
          parseInt(item.income ? item.income.split(".") : 0) * 1000
        ),
      })
    );
    let getMonth = moment(dateToReport, moment.ISO_8601).format("MM-YYYY");
    ExportExcel(arrayExcel, `iHrDoanhThu_Thang${getMonth}`);
  };

  return (
    <Container className="col-12">
      {/* board  */}

      {/* DatePicker */}
      <div lg={12} className="optional_container">
        {/* select */}
        <Row
          xs={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            flexDirection: "row",
          }}
        >
          {/* Loai */}
          <Col xs={12} md={6} lg={6} xl={8} xxl={5}>
            <Form.Label htmlFor="basic-url" style={{ fontWeight: "bold" }}>
              Chi nhánh
            </Form.Label>
            <Form.Select
              value={titleToAPI}
              onChange={(e) => {
                setTitleToAPI(e.target.value);
                setBranchType(
                  e.target.selectedIndex <= 0
                    ? 0
                    : parseInt(
                        dataGetBranch[e.target.selectedIndex - 1]
                          .organization_id
                      )
                );
                onChangeTypeBranch(
                  e.target.value,
                  e.target.selectedIndex <= 0
                    ? 0
                    : parseInt(
                        dataGetBranch[e.target.selectedIndex - 1]
                          .organization_id
                      )
                );
              }}
              xs={12}
              lg={12}
            >
              <option value="">Tất cả</option>
              {dataGetBranch &&
                dataGetBranch.length !== 0 &&
                dataGetBranch.map((item, index) => (
                  <option key={index} value={item.title}>
                    {item.title}
                  </option>
                ))}
            </Form.Select>
          </Col>

          {/* thoi gian */}
          <Col xs={12} lg={6} md={6} xl={4} xxl={0}>
            <Form.Label
              style={{
                fontWeight: "bold",
                width: "auto",
              }}
            >
              Kỳ thanh toán
            </Form.Label>
            <Row
              style={{
                width: "100%",
              }}
            >
              <DatePicker
                top={CONFIG.typeScreen === "xs" ? 10 : 0}
                typeDatePicker="month"
                xl={6}
                lg={6}
                md={6}
                currentDate={dateToReport}
                valueDatePicker={changeDate}
              />
            </Row>
          </Col>
        </Row>
      </div>
      {/* table */}
      <div className="row" style={{ marginTop: 10 }}>
        <div className="col-12">
          <div className="card">
            {reportStore.getSaleReport &&
              reportStore.getSaleReport.length !== 0 && (
                <Col
                  xs={12}
                  style={{
                    display:
                      CONFIG.typeScreen === "xs" ||
                      CONFIG.typeScreen === "sm" ||
                      CONFIG.typeScreen === "md"
                        ? "inline"
                        : "flex",
                    flexDirection:
                      CONFIG.typeScreen === "xs" || CONFIG.typeScreen === "sm"
                        ? "column"
                        : "row",
                    justifyContent: "space-between",
                  }}
                >
                  {/* <Form.Group>
                    <Form.Label style={{ fontWeight: "bold" }}>
                      Dư đầu kỳ:&ensp;
                    </Form.Label>
                    <Form.Label>11,111 đ</Form.Label>
                  </Form.Group> */}

                  {/* <Form.Group>
                    <Form.Label
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Dư cuối kỳ:&ensp;
                    </Form.Label>
                    <Form.Label>22,222 đ</Form.Label>
                  </Form.Group> */}

                  <Form.Group>
                    <Form.Label
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                    </Form.Label>
                    <Form.Label>
                    </Form.Label>
                  </Form.Group>

                  <CustomButton
                    title="Xuất ra Excel"
                    backgroundColor="#ffff"
                    textColor="#B50829"
                    onClick={exportToExcel}
                  />
                </Col>
              )}

            <div className="card__body">
              {reportStore.getSaleReport &&
              reportStore.getSaleReport.length !== 0 ? (
                <Table
                  limit="50"
                  headData={corporateTableHead}
                  renderHead={(item, index) => renderHead(item, index)}
                  bodyData={reportStore.getSaleReport.filter(
                    (item) => item.amount !== 0
                  )}
                  renderBody={(item, index) => renderBody(item, index)}
                  count={
                    reportStore.getSaleReport.filter(
                      (item) => item.amount !== 0
                    ).length
                  }
                />
              ) : (
                <h2 style={{ marginTop: "30px", textAlign: "center" }}>
                  Hiện không có thông tin
                </h2>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Loading isLoading={reportStore.loading} />
    </Container>
  );
};

export default Sale;
