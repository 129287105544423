import * as types from "../type";

const initialState = {
  loading: false,
  listOrganization: [],
};

const OrganizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ORGANIZATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        listOrganization: action.payload.data.data.arrAll,
      };
    default:
      return {
        ...state,
        loading: false,
      };
  }
};

export default OrganizationReducer;
