import React, { useState, useCallback, useEffect } from "react";
import ihr_logo from "../../assets/images/ihr_logo.png";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { loginRequest } from "../../redux/actions/authen.action";
import "./Login.css";
import { Redirect } from "react-router-dom";

const Login = () => {
  const [infoLogin, setInfoLogin] = useState({
    phone: "",
    password: "",
  });
  const dispatch = useDispatch();
  const dataLogin = useSelector((state) => state.authen);

  const [errorLogin, setErrorLogin] = useState({
    phoneErr: "",
    passwordErr: "",
  });

  const onSubmitLogin = useCallback(() => {
    setErrorLogin({
      phoneErr: "",
      passwordErr: "",
    });
    let invalid = true;
    let textPhoneErr = "";
    let textPassErr = "";

    if (!infoLogin.phone) {
      textPhoneErr = "Vui lòng nhập tài khoản ";
      invalid = false;
    }
    if (infoLogin.password.length < 6) {
      textPassErr = "Mật khẩu ít nhất 6 ký tự";
      invalid = false;
    }
    if (!infoLogin.password) {
      textPassErr = "Vui lòng nhập mật khẩu ";
      invalid = false;
    }
    if (!invalid) {
      setErrorLogin({
        phoneErr: textPhoneErr,
        passwordErr: textPassErr,
      });
      return;
    }

    dispatch(loginRequest(infoLogin));
  }, [dispatch, infoLogin]);

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        // callMyFunction();
        onSubmitLogin();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [onSubmitLogin]);

  const LoginSuccess = () => {
    if (dataLogin.access_token) {
      return <Redirect to="/" />;
    } else {
      return <></>;
    }
  };

  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "rgb(229, 229, 229)",
        minHeight: "100vh",
        // placeItems: "center",
        height: "auto",
      }}
    >
      {LoginSuccess()}
      {/* logo */}
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <img src={ihr_logo} alt="..." className="img" />
      </div>

      {/* form  */}

      <Form className="login_container">
        <h2 className="centerText">Đăng Nhập</h2>
        <div className="test">
          <label>Dành cho Admin</label>
        </div>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Số điện thoại</Form.Label>
          <Form.Control
            value={infoLogin.phone}
            onChange={(e) => {
              setInfoLogin({ ...infoLogin, phone: e.target.value });
            }}
          />
          {errorLogin.phoneErr && (
            <Form.Label style={{ color: "#b50829" }}>
              {errorLogin.phoneErr}
            </Form.Label>
          )}
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Mật khẩu</Form.Label>
          <Form.Control
            type="password"
            value={infoLogin.password}
            onChange={(e) => {
              setInfoLogin({ ...infoLogin, password: e.target.value });
            }}
          />
          {errorLogin.passwordErr && (
            <Form.Label style={{ color: "#b50829" }}>
              {errorLogin.passwordErr}
            </Form.Label>
          )}
        </Form.Group>
        <div className="footer">
          <Form.Group
            style={{ textAlign: "end" }}
            className="mb-3"
            controlId="formBasicCheckbox"
          >
            <a href="/forgot-password">Quên mật khẩu</a>
          </Form.Group>
          <Button
            className="submit_login"
            variant="light"
            onClick={onSubmitLogin}
            style={{ backgroundColor: "#b50829", color: "white" }}
          >
            ĐĂNG NHẬP
          </Button>
          {/* <CustomButton
            title="ĐĂNG NHẬP"
            // backgroundColor="#b50829"
            color="white"
            loading={dataLogin.loading}
            submit={() => {}}
          /> */}
        </div>
        {/* <div style={{ marginTop: "20px" }}>
          <CustomButton
            title="ĐĂNG NHẬP"
            // backgroundColor="#b50829"
            color="white"
            loading={dataLogin.loading}
            submit={testSubmit}
          />
        </div> */}
      </Form>
    </div>
  );
};

export default Login;
