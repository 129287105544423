import * as types from "../type";

export const getProvince = (payload) => {
  return {
    type: types.PROVINCE_REQUEST,
    payload: payload,
  };
};

export const getProvinceSuccess = (payload) => {
  return {
    type: types.PROVINCE_SUCCESS,
    payload: payload,
  };
};

export const getPartner = (payload) => {
  return {
    type: types.PARTNER_REQUEST,
    payload: payload,
  };
};

export const getPartnerSuccess = (payload) => {
  return {
    type: types.PARTNER_SUCCESS,
    payload: payload,
  };
};


//twd
export const submit_registerRequest = (payload) => {
  return {
    type: types.REGISTER_REQUEST,
    payload: payload,
  };
};

export const submit_registerSuccess = (payload) => {
  return {
    type: types.REGISTER_SUCCESS,
    payload: payload,
  };
};


//ctv
export const submit_CTV_Request = (payload) => {
  return {
    type: types.REGISTER_CTV_REQUEST,
    payload: payload,
  };
};

export const submit_CTV_Success = (payload) => {
  return {
    type: types.REGISTER_CTV_SUCCESS,
    payload: payload,
  };
};

//CHECK MOBILE
export const check_mobile_Request = (payload) => {
  return {
    type: types.CHECK_MOBILE_REQUEST,
    payload: payload,
  };
};

export const check_mobile_Success = (payload) => {
  return {
    type: types.CHECK_MOBILE_SUCCESS,
    payload: payload,
  };
};
