//Province
export const PROVINCE_REQUEST = 'PROVINCE_REQUEST';
export const PROVINCE_SUCCESS = 'PROVINCE_SUCCESS';
export const PROVINCE_ERROR = 'PROVINCE_ERROR';

//admin get partner
export const PARTNER_REQUEST = 'PARTNER_REQUEST';
export const PARTNER_SUCCESS = 'PARTNER_SUCCESS';
export const PARTNER_ERROR = 'PARTNER_ERROR';

//post register TWD
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';

//post register CTV
export const REGISTER_CTV_REQUEST = 'REGISTER_CTV_REQUEST';
export const REGISTER_CTV_SUCCESS = 'REGISTER_CTV_SUCCESS';
export const REGISTER_CTV_ERROR = 'REGISTER_CTV_ERROR';

//check mobile
export const CHECK_MOBILE_REQUEST = 'CHECK_MOBILE_REQUEST';
export const CHECK_MOBILE_SUCCESS = 'CHECK_MOBILE_SUCCESS';
export const CHECK_MOBILE_ERROR = 'CHECK_MOBILE_ERROR';

//check login
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

//check logout
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';


//revenue
export const REVENUE_REQUEST = 'REVENUE_REQUEST';
export const REVENUE_SUCCESS = 'REVENUE_SUCCESS';
export const REVENUE_ERROR = 'REVENUE_ERROR';

//report
export const REPORT_REQUEST = 'REPORT_REQUEST';
export const REPORT_SUCCESS = 'REPORT_SUCCESS';
export const REPORT_ERROR = 'REPORT_ERROR';
export const GET_BRANCH_REQUEST = 'GET_BRANCH_REQUEST';
export const GET_BRANCH_SUCCESS = 'GET_BRANCH_SUCCESS';
export const GET_BRANCH_ERROR = 'GET_BRANCH_ERROR';

//sale
export const REPORT_SALE_REQUEST = 'REPORT_SALE_REQUEST';
export const REPORT_SALE_SUCCESS = 'REPORT_SALE_SUCCESS';
export const REPORT_SALE_ERROR = 'REPORT_SALE_ERROR';

//member
export const GET_MEMBER_REQUEST = 'GET_MEMBER_REQUEST';
export const GET_MEMBER_SUCCESS = 'GET_MEMBER_SUCCESS';
export const GET_MEMBER_ERROR = 'GET_MEMBER_ERROR';

//update info user
export const UPDATE_INFO_USER_REQUEST = 'UPDATE_INFO_USER_REQUEST';
export const UPDATE_INFO_USER_SUCCESS = 'UPDATE_INFO_USER_SUCCESS';
export const UPDATE_INFO_USER_ERROR = 'UPDATE_INFO_USER_ERROR';

//member
export const GET_LIST_CUSTOMER_JHL_REQUEST = 'GET_LIST_CUSTOMER_JHL_REQUEST';
export const GET_LIST_CUSTOMER_JHL_SUCCESS = 'GET_LIST_CUSTOMER_JHL_SUCCESS';
export const GET_LIST_CUSTOMER_JHL_ERROR = 'GET_LIST_CUSTOMER_JHL_ERROR';

//organization
export const GET_ORGANIZATION_REQUEST = 'GET_ORGANIZATION_REQUEST';
export const GET_ORGANIZATION_SUCCESS = 'GET_ORGANIZATION_SUCCESS';
export const GET_ORGANIZATION_ERROR = 'GET_ORGANIZATION_ERROR';

//otp
export const GET_OTP_REQUEST = 'GET_OTP_REQUEST';
export const GET_OTP_SUCCESS = 'GET_OTP_SUCCESS';
export const GET_OTP_ERROR = 'GET_OTP_ERROR';

//otp
export const VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_ERROR = 'VERIFY_OTP_ERROR';
//payment
export const GET_PAYMENT_REQUEST = 'GET_PAYMENT_REQUEST';
export const GET_PAYMENT_SUCCESS = 'GET_PAYMENT_SUCCESS';
export const GET_PAYMENT_ERROR = 'GET_PAYMENT_ERROR';
