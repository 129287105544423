import axios from "axios";
import CONFIG from "../config/config";

export const getProvinceApi = (p_ref_prefix) => {
  return axios.get(`${CONFIG.url}/get-partner?p_ref_prefix=${p_ref_prefix}`);
};

export const getPartnerApi = (p_ref_code) => {
  return axios.get(`${CONFIG.url}/admin-get-partner?p_ref_code=${p_ref_code}`);
};

export const submitRegisterApi = (body) => {
  return axios.post(`${CONFIG.url}/register/twd`, {
    p_partner_id: 1,
    p_mobile: body.phone,
    p_password: body.pass,
    p_fullname: body.name,
    p_gender: body.male,
    p_org_id: body.organization_id,
    p_department_name: body.department_name,
    p_email: body.email,
    p_identity: body.identity,
    p_ref_code: body.ref_code,
  });
};

export const registerCTVApi = (body) => {
  return axios.post(`${CONFIG.url}/register/ctv`, {
    p_mobile: body.phone,
    p_password: body.pass,
    p_fullname: body.name,
    p_gender: body.male,
    p_email: body.email,
    p_identity: body.identity,
    p_ref_code: body.ref_code,
  });
};

export const checkMobileApi = (p_mobile) => {
  return axios.get(`${CONFIG.url}/check-mobile?p_mobile`, {
    p_mobile,
  });
};
