import * as types from "../../type";
import { takeLatest, call, put } from "redux-saga/effects";
import { loginAPI } from "../../../api/authen.api";
import { loginSuccess, loginError } from "../../actions/authen.action";

function* authenSaga(action) {
  try {
    const submit = yield call(loginAPI, action.payload);
    // console.log('Đăng nhập thành công !');
    // debugger
    localStorage.setItem("accessToken", submit.data.accessToken);
    localStorage.setItem("refreshToken", submit.data.refreshToken);
    localStorage.setItem("infoUser", JSON.stringify(submit.data.infoUser));
    localStorage.setItem("organize", JSON.stringify(submit.data.organize));
    yield put(loginSuccess(submit));
  } catch (err) {
    alert("Thông tin đăng nhập không đúng !");
    yield put(loginError(err));
  }
}

export default function* authencationSaga() {
  yield takeLatest(types.LOGIN_REQUEST, authenSaga);
}
