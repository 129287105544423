import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import showPassWord from "../../assets/images/showPassWord.png";
import { submit_CTV_Request } from "../../redux/actions/register.action";
import { useDispatch } from "react-redux";
import API from "../../service/api";
import ClientCaptcha from "react-client-captcha";
import { checkMobile, checkEmail } from "../../validation/registerValidate";
import CONFIG from "../../config/config";
// import { Alert } from "react-native";

const RegisterCTV = (props) => {
  const [actions, setActions] = useState({
    phone: "",
    pass: "",
    name: "",
    male: "0",
    email: "",
    identity: "",
    ref_code: "",
  });
  const [isShowPassWord, setShowPassWord] = useState(false);
  const [captchaCode, setCaptchaCode] = useState("");
  const [inputCaptcha, setInputCaptcha] = useState("");

  const dispatch = useDispatch();

  const [errors, setErrors] = useState({
    phoneErr: "",
    passErr: "",
    nameErr: "",
    emailErr: "",
    captchaErr: "",
  });

  const splitUrl = window.location.href.split("&");

  const urlElements = splitUrl[0].split("=");

  const submit_register = async () => {
    setErrors({
      phoneErr: "",
      passErr: "",
      nameErr: "",
      emailErr: "",
      captchaErr: "",
    });

    //check ref_code exits
    var response_check_ref;
    await API.get(`${CONFIG.url}/check-ref-code?p_ref_code=${urlElements[1]}`)
      .then((response) => {
        response_check_ref = response;
      })
      .catch((e) => {
        response_check_ref = undefined;
        isPassValidate = false;
      });
    if (response_check_ref === undefined) {
        alert("Mã giới thiệu không hợp lệ. Vui lòng liên hệ 0982828216");
      return;
    }
    var phoneErr = "";
    var passErr = "";
    var nameErr = "";
    var emailErr = "";
    var captchaErr = "";
    var isPassValidate = true;

    await API.get(`${CONFIG.url}/check-mobile?p_mobile=${actions.phone}`).then(
      (response) => {
        if (response.data.data.checkPhone[0][0].chkmob === 1) {
          setErrors({ ...actions, phoneErr: "Số điện thoại đã được đăng ký" });
          return;
        }
      }
    );

    if (!checkMobile(actions.phone)) {
      isPassValidate = false;
      phoneErr = "Vui lòng nhập đúng số điện thoại";
    }

    if (actions.pass.length < 6) {
      isPassValidate = false;
      passErr = "Mật khẩu ít nhất 6 ký tự";
    }
    if (!actions.pass) {
      isPassValidate = false;
      passErr = "Vui lòng nhập mật khẩu";
    }
    if (!actions.name) {
      isPassValidate = false;
      nameErr = "Vui lòng nhập tên";
    }
    if (!checkEmail(actions.email)) {
      isPassValidate = false;
      emailErr = "Vui lòng nhập Email đúng định dạng";
    }
    if (captchaCode !== inputCaptcha) {
      isPassValidate = false;
      captchaErr = "Mã xác nhận chưa đúng";
    }
    if (!isPassValidate) {
      setErrors({
        phoneErr: phoneErr,
        passErr: passErr,
        nameErr: nameErr,
        emailErr: emailErr,
        captchaErr: captchaErr,
      });
      return;
    }
    //send request
    dispatch(
      submit_CTV_Request({
        ...actions,
        phone: actions.phone.trim(),
        ref_code: urlElements[1].toUpperCase(),
      })
    );
  };

  return (
    <>
      {/* title */}
      <Container className="register_container" style={{ maxWidth: 760 }}>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <h1>ĐĂNG KÝ MÃ CỘNG TÁC VIÊN</h1>
        </div>
        <label style={{ display: "block" }}>
          Cơ quan chủ quản:{" "}
          <label style={{ fontWeight: "bold" }}>{props.title}</label>
        </label>

        <Row style={{ marginTop: "30px" }}>
          <Col xs="12" lg="6">
            <Form.Label htmlFor="basic-url11" style={{ fontWeight: "bold" }}>
              Điện thoại
            </Form.Label>
            <Form.Label style={{ color: "red" }}>*</Form.Label>
            <InputGroup
              className="mb-3"
              onChange={(e) => {
                setActions({
                  ...actions,
                  phone: e.target.value,
                });
              }}
            >
              <FormControl
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </InputGroup>
            {errors.phoneErr && (
              <Form.Label style={{ color: "red" }}>
                {errors.phoneErr}
              </Form.Label>
            )}
          </Col>
          <Col xs="12" lg="6">
            <Form.Label htmlFor="basic-url2" style={{ fontWeight: "bold" }}>
              Mật khẩu
            </Form.Label>
            <Form.Label style={{ color: "red" }}>*</Form.Label>
            <InputGroup
              className="mb-3 "
              style={{ position: "relative" }}
              onChange={(e) => {
                setActions({
                  ...actions,
                  pass: e.target.value,
                });
              }}
            >
              <img
                className="td"
                onClick={() => {
                  setShowPassWord(!isShowPassWord);
                }}
                width="20px"
                height="20px"
                src={showPassWord}
                alt="..."
              />
              <FormControl
                type={isShowPassWord ? "text" : "password"}
                aria-describedby="basic-addon3"
              />
            </InputGroup>
            {errors.passErr && (
              <Form.Label style={{ color: "red" }}>{errors.passErr}</Form.Label>
            )}
          </Col>
        </Row>

        {/* ho ten && gioi tinh */}
        <Row>
          <Col xs="12" lg="6">
            <Form.Label htmlFor="basic-url12" style={{ fontWeight: "bold" }}>
              Họ và tên
            </Form.Label>
            <Form.Label style={{ color: "red" }}>*</Form.Label>
            <InputGroup
              className="mb-3"
              onChange={(e) => {
                setActions({
                  ...actions,
                  name: e.target.value,
                });
              }}
            >
              <FormControl
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              />
            </InputGroup>
            {errors.nameErr && (
              <Form.Label style={{ color: "red" }}>{errors.nameErr}</Form.Label>
            )}
          </Col>
          <Col xs="12" lg="6">
            <Form.Label htmlFor="basic-url" style={{ fontWeight: "bold" }}>
              Giới tính
            </Form.Label>
            <Form.Select
              value={actions.male}
              onChange={(e) => {
                setActions({
                  ...actions,
                  male: e.target.value,
                });
              }}
              aria-label="Default select example"
            >
              <option value="0">Nam</option>
              <option value="1">Nữ</option>
            </Form.Select>
          </Col>
        </Row>

        {/* Email CMND */}
        <Row>
          <Col xs="12" lg="6">
            <Form.Label htmlFor="basic-url4" style={{ fontWeight: "bold" }}>
              Email
            </Form.Label>
            <Form.Label style={{ color: "red" }}>*</Form.Label>
            <InputGroup
              className="mb-3"
              onChange={(e) => {
                setActions({
                  ...actions,
                  email: e.target.value,
                });
              }}
            >
              <FormControl
                id="basic-url5"
                aria-describedby="basic-addon3"
                placeholder="example@gmail.com"
              />
            </InputGroup>
            {errors.emailErr && (
              <Form.Label style={{ color: "red" }}>
                {errors.emailErr}
              </Form.Label>
            )}
          </Col>
          <Col xs="12" lg="6">
            <Form.Label htmlFor="basic-url6" style={{ fontWeight: "bold" }}>
              Số CMND/ CCCD
            </Form.Label>
            <InputGroup
              className="mb-3"
              onChange={(e) => {
                setActions({
                  ...actions,
                  identity: e.target.value,
                });
              }}
            >
              <FormControl id="basic-url7" aria-describedby="basic-addon3" />
            </InputGroup>
          </Col>
        </Row>

        {/* cap cha */}
        <Row>
          <Col
            xs={{ span: 0, offset: 0 }}
            lg={{ span: 5, offset: 4 }}
            xl={{ span: 6, offset: 3 }}
            md={{ span: 0, offset: 0 }}
          >
            <Form.Label htmlFor="basic-url9" style={{ fontWeight: "bold" }}>
              Nhập mã xác nhận
            </Form.Label>
            <InputGroup
              onChange={(e) => {
                setInputCaptcha(e.target.value);
              }}
              className="mb-3"
            >
              <FormControl id="basic-url10" aria-describedby="basic-addon3" />
              <div style={{ marginLeft: "10px" }}>
                <ClientCaptcha
                  captchaCode={(captchaCode) => {
                    setCaptchaCode(captchaCode);
                  }}
                />
              </div>
            </InputGroup>
            {errors.captchaErr && (
              <Form.Label style={{ color: "red" }}>
                {errors.captchaErr}
              </Form.Label>
            )}
          </Col>
        </Row>

        {/* submit */}
        <Row
          style={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Col xs={6} lg={4} md={5} xl={5}>
            <Button
              className="btn_register"
              onClick={submit_register}
              variant="danger"
            >
              Đăng ký
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RegisterCTV;
