/* eslint-disable no-lone-blocks */
import React, { useState, useCallback, useEffect } from "react";
import "./Profile.css";
import InfoUserOrganization from "../organization/InfoUserOrganization";
import CONFIG from "../../config/config";
import MD5 from "crypto-js/md5";
import { checkMobile, checkEmail } from "../../validation/registerValidate";
import { Modal } from "react-bootstrap";
import API from "../../service/api";
import { useDispatch } from "react-redux";
import { updateInfoUserRequest } from "../../redux/actions/member.action";

const Profile = () => {
  const [actions, setActions] = useState({
    passWord: "",
    newPassWord: "",
    confirmPassWord: "",
    fullName: CONFIG.fullName,
    mobile: CONFIG.mobile,
    existMobile: false,
    identity: CONFIG.identity | 0,
    email: CONFIG.email,
    error: "",
    // success: false,
  });

  const [show, setShow] = useState(false);
  const [getCompanyName, setCompanyName] = useState("");
  const dispatch = useDispatch();
  const onClose = useCallback(() => {
    setShow(!show);
  }, [show]);

  const md5 = (e) => {
    var data = MD5(e).toString();
    return data;
  };

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShow(!show);
        window.location.reload();
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    API.get(
      `${CONFIG.url}/getCompanyName?p_admin_user_id=${CONFIG.admin_user_id}`
    ).then((response) => {
      setCompanyName(
        response.data.data.getCompanyNameControl[0][0].companyName
      );
    });
  }, []);

  const onSubmit = async () => {
    if (actions.passWord && md5(actions.passWord) !== CONFIG.password) {
      setActions({
        ...actions,
        error: "Mật khẩu chưa đúng !",
      });
      setShow(!show);
      return;
    }

    if (actions.passWord && !actions.newPassWord) {
      setActions({
        ...actions,
        error: "Bạn chưa nhập mật khẩu mới",
      });
      setShow(!show);
      return;
    }

    if (actions.newPassWord !== actions.confirmPassWord) {
      setActions({
        ...actions,
        error: "Nhập lại mật khẩu mới chưa đúng !",
      });
      setShow(!show);
      return;
    }
    if (!checkMobile(actions.mobile)) {
      setActions({
        ...actions,
        error: "Số điện thoại không hợp lệ !",
      });
      setShow(!show);
      return;
    }

    if (actions.mobile !== CONFIG.mobile) {
      var existMobile = false;
      await API.get(
        `${CONFIG.url}/check-mobile?p_mobile=${actions.mobile}`
      ).then((response) => {
        if (response.data.data.checkPhone[0][0].chkmob === 1) {
          setActions({
            ...actions,
            error: "Số điện thoại đã tồn tại !",
            existMobile: true,
          });
          existMobile = true;
          setShow(!show);
        }
      });
      if (existMobile) {
        return;
      }
    }

    if (!checkEmail(actions.email)) {
      setActions({
        ...actions,
        error: "Email không hợp lệ !",
      });
      setShow(!show);
      return;
    }

    var newDataInfo = {
      p_password: actions.passWord ? actions.newPassWord : "",
      p_full_name: actions.fullName,
      p_mobile: actions.mobile,
      p_identity: actions.identity,
      p_email: actions.email,
      p_admin_user_id: CONFIG.admin_user_id,
    };
    dispatch(updateInfoUserRequest(newDataInfo));

    setActions({
      ...actions,
      error: "Cập nhật thông tin thành công !",
    });
    setShow(!show);
  };

  return (
    <div className="profile_container">
      <Modal show={show} onHide={onClose}>
        <Modal.Title
          id="example-custom-modal-styling-title"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {actions.error}
        </Modal.Title>
      </Modal>
      <div className="profile_header">
        <h2 className="title_header">Tài khoản</h2>
        <InfoUserOrganization />
        
      </div>
      <div className="profile_content">
        <div className="select_partner">
          <span className="select_name">
            Đối tác<span className="star">*</span>
          </span>
          <select
            disabled={true}
            className="partner"
            name="partner"
            id="partner"
          >
            <option value="cao-bang">{getCompanyName}</option>
          </select>
        </div>
        <div className="select_partner">
          <span className="username_content">Tên đăng nhập</span>
          <input
            type="text"
            value={CONFIG.username}
            disabled={true}
            className="value_username"
          />
        </div>
        <div className="data_content">
          {/* mat khau */}
          <div className="input_content">
            <span className="name_content">
              Mật khẩu <span className="star">*</span>
            </span>
            <input
              type="password"
              onChange={(e) => {
                setActions({ ...actions, passWord: e.target.value });
              }}
              className="value_content"
            />
            {md5(actions.passWord) !== CONFIG.password && actions.passWord && (
              <span style={{ color: "red" }}>Mật khẩu chưa đúng</span>
            )}
            {CONFIG.typeScreen === "xs" && !actions.passWord && (
              <span style={{ color: "red" }}>
                Nhập mật khẩu mới nếu bạn muốn đổi mật khẩu, nếu không vui lòng
                bỏ trống
              </span>
            )}
          </div>
          <div className="input_content">
            <span className="name_content">Mật khẩu mới</span>
            <input
              type="password"
              className="value_content"
              onChange={(e) => {
                setActions({ ...actions, newPassWord: e.target.value });
              }}
            />
            {actions.newPassWord && actions.newPassWord.length < 6 && (
              <span style={{ color: "red" }}>Mật khẩu tối thiểu 6 ký tự</span>
            )}
          </div>
          <div className="input_content">
            <span className="name_content">Xác nhận mật khẩu mới</span>
            <input
              type="password"
              className="value_content"
              onChange={(e) => {
                setActions({ ...actions, confirmPassWord: e.target.value });
              }}
            />
            {actions.confirmPassWord &&
              actions.confirmPassWord !== actions.newPassWord && (
                <span style={{ color: "red" }}>
                  Mật khẩu mới phải trùng khớp
                </span>
              )}
          </div>
        </div>

        {CONFIG.typeScreen !== "xs" && !actions.passWord && (
          <div className="data_content">
            <span style={{ color: "red" }}>
              Nhập mật khẩu mới nếu bạn muốn đổi mật khẩu, nếu không vui lòng bỏ
              trống
            </span>
          </div>
        )}

        {/* ho ten && dien thoai */}
        <div className="data_content2">
          <div className="input_content2">
            <span className="name_content">
              Họ và tên <span className="star">*</span>
            </span>
            <input
              type="text"
              value={actions.fullName}
              onChange={(e) => {
                setActions({
                  ...actions,
                  fullName: e.target.value,
                });
              }}
              className="value_content"
            />
          </div>
          <div className="input_content2">
            <span className="name_content">
              Điện thoại <span className="star">*</span>
            </span>
            <input
              type="text"
              defaultValue={actions.mobile}
              className="value_content"
              onChange={(e) => {
                setActions({
                  ...actions,
                  mobile: e.target.value,
                  existMobile: false,
                });
              }}
            />

            {actions.mobile && !checkMobile(actions.mobile) && (
              <span style={{ color: "red" }}>Số điện thoại không hợp lệ !</span>
            )}

            {actions.existMobile && (
              <span style={{ color: "red" }}>Số điện thoại đã tồn tại !</span>
            )}
          </div>
        </div>
        <div className="data_content2">
          <div className="input_content2">
            <span className="name_content">Số CMND/CCCD</span>
            <input
              type="text"
              value={actions.identity}
              className="value_content"
              onChange={(e) => {
                setActions({
                  ...actions,
                  identity: e.target.value,
                });
              }}
            />
          </div>
          <div className="input_content2">
            <span className="name_content">Email</span>
            <input
              type="text"
              value={actions.email}
              className="value_content"
              onChange={(e) => {
                setActions({
                  ...actions,
                  email: e.target.value,
                });
              }}
            />
            {actions.email && !checkEmail(actions.email) && (
              <span style={{ color: "red" }}>Email không hợp lệ !</span>
            )}
          </div>
        </div>

        <div className="input_submit">
          <p
            onClick={onSubmit}
            className="text_submit"
            style={{ fontWeight: "bold" }}
          >
            Lưu
          </p>
        </div>
      </div>
    </div>
  );
};

export default Profile;
