import * as types from "../type";

export const getMemberRequest = (payload) => {
  return {
    type: types.GET_MEMBER_REQUEST,
    payload: payload,
  };
};

export const getMemberSuccess = (payload) => {
  return {
    type: types.GET_MEMBER_SUCCESS,
    payload: payload,
  };
};

export const getMemberError = (payload) => {
  return {
    type: types.GET_MEMBER_ERROR,
    payload: payload,
  };
};

export const updateInfoUserRequest = (payload) => {
  return {
    type: types.UPDATE_INFO_USER_REQUEST,
    payload: payload,
  };
};

export const updateInfoUserSuccess = (payload) => {
  return {
    type: types.UPDATE_INFO_USER_SUCCESS,
    payload: payload,
  };
};

export const updateInfoUserError = (payload) => {
  return {
    type: types.UPDATE_INFO_USER_ERROR,
    payload: payload,
  };
};
