import * as types from "../../type";
import { takeLatest, call, put } from "redux-saga/effects";
import { paymentSuccess, paymentError } from "../../actions/payment.action";
import { paymentAPI } from "../../../api/payment.api";

function* payment(action) {
  try {
    const submit = yield call(paymentAPI, action.payload);
    yield put(paymentSuccess(submit));
  } catch (err) {
    yield put(paymentError(err));
  }
}

export default function* paymentSaga() {
  yield takeLatest(types.GET_PAYMENT_REQUEST, payment);
}
