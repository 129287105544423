import React from "react";
import "./Workers.css";

const Workers = () => {
  return (
    <div className="workers_container">
      <h2>Người Lao Động</h2>
      <div className="search__workers">
        <input type="text" className="input_search__workers" />
        <select class="choice" name="choice" id="choice">
          <option value="">Ngày tạo</option>
          <option value="ha-noi">Mã giới thiệu</option>
          <option value="ha-giang">Họ và tên</option>
          <option value="cao-bang">LCS</option>

        </select>
        <p type="Submit" className="Submit__workers">Tìm Kiếm</p>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th scope="col">Ngày tạo</th>
            <th scope="col">User ID</th>
            <th scope="col">Mã GT</th>
            <th scope="col">Ảnh</th>
            <th scope="col">Họ và Tên</th>
            <th scope="col">Điện thoại</th>
            <th scope="col">Địa chỉ</th>
            <th scope="col">LCS</th>
            <th scope="col">Thao tác</th>
          </tr>
        </thead>
       
      </table>
      <div class="table__pagination"><div class="table__pagination-item active">1</div><div class="table__pagination-item ">2</div><div class="table__pagination-item ">3</div><div class="table__pagination-item ">4</div></div>

    </div>

  );
};

export default Workers;
