/* eslint-disable array-callback-return */
import React, { useState, useEffect, useCallback, useMemo } from "react";
import "./Installer.css";
import {
	Container,
	Col,
	Row,
	Form,
	InputGroup,
	FormControl,
	ToastContainer,
	Button,
} from "react-bootstrap";
import DatePicker from "../../components/common/datePicker";
import CONFIG from "../../config/config";
import API from "../../service/api";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { listCustomerJHLRequest } from "../../redux/actions/listCustomerJHL";
import Loading from "../../components/Loading";
import CustomButton from "../../components/common/button";
import ExportExcel from "../../components/common/ExportExcel";
import Table from "../../components/table/Table";

import { customerAPI } from "../../api/customer.api";

const Installer = () => {
	const listCustomerStore = useSelector((state) => state.listCustomer);
	const dispatch = useDispatch();
	const [status, setStatus] = useState("");
	//province
	const [provinceValue, setProvinceValue] = useState("");
	const [province, setProvince] = useState([]);
	//ref
	const [refPerson, setRefPerson] = useState("");
	const [refPersonArr, setRefPersonArr] = useState([]);
	//input name
	const [namePerson, setNamePerson] = useState("");
	//time
	const [currentStartDate, setCurrentStartDate] = useState(
		moment(new Date(), moment.ISO_8601)
			.startOf("month")
			.format("YYYY-MM-DD 00:00:00")
	);
	const [currentEndDate, setCurrentEndDate] = useState(
		moment(new Date(), moment.ISO_8601).format("YYYY-MM-DD 23:59:59")
	);

	const corporateTableHead = useMemo(
		() => [
			"",
			"Người giới thiệu",
			"Ngày tạo",
			"Ứng viên",
			"Điện thoại",
			"Nhu cầu",
			"Khu vực",
			"Giới tính",
			"Hồ sơ",
		],
		[]
	);

	const renderHead = useCallback(
		(item, index) => (
			<th style={{ border: "1px solid #ccc" }} key={index}>
				{item}
			</th>
		),
		[]
	);

	const arrStatus = [
		{ key: "", name: "Tất cả" },
		{ key: "st01", name: "Cần tìm việc làm" },
		{ key: "st02", name: "Cần học nghề" },
		{ key: "st03", name: "Nhu cầu du học" },
		{ key: "st04", name: "Nhu cầu XKLĐ" },
	];

	const renderBody = (item, index) => (
		<tr key={index}>
			<td style={{ border: "1px solid #ccc", textAlign: "center" }}>
				{index + 1}
			</td>
			<td style={{ border: "1px solid #ccc" }}>{item.sale_name}</td>
			<td style={{ border: "1px solid #ccc" }}>
				{moment(
					item.created_at.replace("T", " ").replace(".000Z", ""),
					moment.ISO_8601
				).format("DD-MM-YYYY hh:mm:ss")}
			</td>
			<td style={{ border: "1px solid #ccc" }}>{item.candidate_name}</td>
			<td style={{ border: "1px solid #ccc", textAlign: "center" }}>
				{item.phone}
			</td>
			<td style={{ border: "1px solid #ccc" }}>{returnStatus(item.status)}</td>
			<td style={{ border: "1px solid #ccc", textAlign: "center" }}>
				{item.province_name}
			</td>
			<td style={{ border: "1px solid #ccc", textAlign: "center" }}>
				{item.gender === 0 ? "Nữ" : "Nam"}
			</td>
			<td style={{ border: "1px solid #ccc", textAlign: "center" }}>
				<a
					href={(CONFIG.viewProfileUser + "/" + item.slug).split(" ").join("")}
					rel="noopener noreferrer"
					target="_blank"
				>
					{/* variant="danger" */}
					<Button
						style={{
							color: "#ffff",
							backgroundColor: "#B50829",
							borderColor: "#B50829",
						}}
					>
						Xem
					</Button>
				</a>
			</td>
		</tr>
	);

	const returnStatus = (statusString) => {
		var result = [{}];
		if (statusString) {
			//neu co Nhu cầu thì
			//lấy ra mảng các nhu cầu của user
			var arrStatusTemp = statusString.split(",");
			//sau đó đưa nó vào object
			var objStatus = [{}];
			for (let index = 0; index < arrStatusTemp.length; index++) {
				objStatus.push({ status: arrStatusTemp[index] });
			}
			//map 2 object xem trùng key ko, trùng => add vào object result
			objStatus.map((itemObj) => {
				arrStatus.map((itemArr) => {
					if (itemObj.status === itemArr.key) {
						result.push({ status: itemArr.name });
					}
				});
			});
			//in object result
			return result.map((item, index) => (
				<label key={index} style={{ display: "block" }}>
					{item.status}
				</label>
			));
		}
	};

	//   getProvinceJHL
	useEffect(() => {
		API.get(`${CONFIG.url}/getProvinceJHL`).then((response) => {
			setProvince(response.data.data.getProvinceJHL);
		});
	}, []);

	useEffect(() => {
		API.get(
			`${CONFIG.url}/getNameAndRefJHL?p_admin_user_id=${CONFIG.admin_user_id}`
		).then((response) => {
			setRefPersonArr(response.data.data.getNameAndRefJHL);
		});
	}, []);

	const changeStartDate = useCallback((e) => {
		setCurrentStartDate(
			moment(e, moment.ISO_8601).format("YYYY-MM-DD 00:00:00")
		);
	}, []);

	const changeEndDate = useCallback((e) => {
		setCurrentEndDate(moment(e, moment.ISO_8601).format("YYYY-MM-DD 23:59:59"));
	}, []);

	const exportToExcel = async () => {
		var arrayExcel = [];

		const payload = {
			refPerson,
			status,
			provinceValue,
			namePerson,
			currentStartDate,
			currentEndDate,
			offset: 0,
			limit: 999999999,
		};

		const excelCustomer = await customerAPI(payload);

		excelCustomer.data.data.executeSQL[0].map((item) =>
			arrayExcel.push({
				"Người giới thiệu": item.sale_name,
				"Ngày tạo": moment(
					item.created_at.replace("T", " ").replace(".000Z", ""),
					moment.ISO_8601
				).format("DD-MM-YYYY 23:59:59"),
				"Ứng viên": item.candidate_name,
				"Điện thoại": item.phone,
				"Nhu cầu": item.status,
				"Khu vực": item.province_name,
				"Giới tính": item.gender === 0 ? "Nữ" : "Nam",
			})
		);
		let getMonth = moment(CONFIG.currentStartDate, moment.ISO_8601).format(
			"MM-YYYY"
		);
		ExportExcel(arrayExcel, `iHrDanhSachCaiApp${getMonth}`);
	};

	const submit = () => {
		const payload = {
			refPerson,
			status,
			provinceValue,
			namePerson,
			currentStartDate,
			currentEndDate,
			offset: 0,
		};
		dispatch(listCustomerJHLRequest(payload));
	};

	const paginateReport = (offset) => {
		const payload = {
			refPerson,
			status,
			provinceValue,
			namePerson,
			currentStartDate,
			currentEndDate,
			offset,
		};
		dispatch(listCustomerJHLRequest(payload));
	};

	return (
		<Container className="installer_container">
			<Row style={{ display: "flex", justifyContent: "space-between" }}>
				{/* nguoi gioi thieu */}
				<Col xxl={3}>
					<label className="bold_text">Người giới thiệu</label>
					<Col>
						<Form.Select
							value={refPerson}
							onChange={(e) => {
								setRefPerson(e.target.value);
							}}
							className="mb-3"
						>
							<option value="">Tất cả</option>
							{refPersonArr.length !== 0 &&
								refPersonArr.map((item, index) => (
									<option key={index} value={item.ref_code}>
										{item.admin_name}
									</option>
								))}
						</Form.Select>
					</Col>
				</Col>

				{/* nhu cau */}
				<Col xxl={3}>
					<label className="bold_text">Nhu cầu</label>
					<Col>
						<Form.Select
							value={status}
							onChange={(e) => {
								setStatus(e.target.value);
							}}
							className="mb-3"
						>
							{arrStatus.map((item, index) => (
								<option key={index} value={item.key}>
									{item.name}
								</option>
							))}
						</Form.Select>
					</Col>
				</Col>

				{/* khu vuc */}
				<Col xxl={3}>
					<label className="bold_text">Khu vực</label>
					<Col>
						<Form.Select
							value={provinceValue}
							onChange={(e) => {
								setProvinceValue(e.target.value);
							}}
							className="mb-3"
						>
							<option value="">Tất cả</option>
							{province.length !== 0 &&
								province.map((item, index) => (
									<option key={index} value={item.province_code}>
										{item.province_name}
									</option>
								))}
						</Form.Select>
					</Col>
				</Col>

				<Col xxl={3}></Col>
			</Row>

			<Row
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					marginTop: 5,
				}}
			>
				{/* ten ung vien */}
				<Col xs={12} xxl={3}>
					<label className="bold_text">Tên ứng viên</label>
					<Col>
						<InputGroup
							value={namePerson}
							onChange={(e) => {
								setNamePerson(e.target.value);
							}}
							className="mb-3"
						>
							<FormControl
								aria-label="Username"
								aria-describedby="basic-addon1"
							/>
						</InputGroup>
					</Col>
				</Col>

				{/* time */}
				<Col xs={12} lg={8} xxl={4}>
					<label className="bold_text">Thời gian</label>
					<Row
						style={{
							height: CONFIG.typeScreen === "xs" ? 40 : 50,
						}}
					>
						<DatePicker
							top={CONFIG.typeScreen === "xs" ? 10 : 0}
							xl={6}
							lg={6}
							md={6}
							currentDate={currentStartDate}
							valueDatePicker={changeStartDate}
						/>
						<DatePicker
							top={CONFIG.typeScreen === "xs" ? 10 : 0}
							xl={6}
							md={6}
							lg={6}
							currentDate={currentEndDate}
							valueDatePicker={changeEndDate}
						/>
					</Row>
				</Col>

				{/* submit */}
				<Col
					style={{
						display: "flex",
						justifyContent: "center",
						marginTop: CONFIG.typeScreen === "xs" ? 60 : 15,
					}}
					xxl={2}
					lg={4}
					xs={12}
				>
					<Button
						onClick={submit}
						style={{
							width:
								CONFIG.typeScreen === "xs" || CONFIG.typeScreen === "md"
									? "100%"
									: "auto",
							backgroundColor: "#B50829",
							borderColor: "#B50829",
						}}
						variant="danger"
					>
						<label style={{ fontWeight: "bold" }}>Thực hiện</label>
					</Button>
				</Col>
				<Col xxl={3}></Col>
			</Row>

			{/* table */}
			{/* table */}
			<div className="row" style={{ marginTop: 10 }}>
				<div className="col-12">
					<div className="card">
						{listCustomerStore.listCustomer &&
							listCustomerStore.listCustomer.length !== 0 && (
								<Col
									xs={12}
									style={{
										display:
											CONFIG.typeScreen === "xs" ||
											CONFIG.typeScreen === "sm" ||
											CONFIG.typeScreen === "md"
												? "inline"
												: "flex",
										flexDirection:
											CONFIG.typeScreen === "xs" || CONFIG.typeScreen === "sm"
												? "column"
												: "row",
										justifyContent: "space-between",
									}}
								>
									<Form.Group>
										<Form.Label style={{ fontWeight: "bold" }}>
											Tổng:&ensp;
										</Form.Label>
										<Form.Label>
											{listCustomerStore.listCustomerTotal}
										</Form.Label>
									</Form.Group>

									<Form.Group>
										<Form.Label
											style={{
												fontWeight: "bold",
											}}
										>
											{/* Tổng:&ensp; */}
										</Form.Label>
										<Form.Label>
											{/* {money_format(listCustomerStore.listCustomer.totalAppCorporate)}&nbsp;đ */}
										</Form.Label>
									</Form.Group>

									<CustomButton
										title="Xuất ra Excel"
										backgroundColor="#ffff"
										textColor="#B50829"
										onClick={exportToExcel}
									/>
								</Col>
							)}

						<div className="card__body">
							{listCustomerStore.listCustomer &&
							listCustomerStore.listCustomer.length !== 0 ? (
								<Table
									limit="50"
									headData={corporateTableHead}
									renderHead={(item, index) => renderHead(item, index)}
									bodyData={listCustomerStore.listCustomer}
									renderBody={(item, index) => renderBody(item, index)}
									count={listCustomerStore.listCustomerTotal}
									paginateReport={paginateReport}
								/>
							) : (
								<h2 style={{ marginTop: "30px", textAlign: "center" }}>
									Hiện không có thông tin
								</h2>
							)}
						</div>
					</div>
				</div>
			</div>
			<ToastContainer />
			<Loading isLoading={listCustomerStore.loading} />
		</Container>
	);
};

export default Installer;
