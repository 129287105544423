import axios from "axios";
import CONFIG from "../config/config";

export const OTP_requestAPI = (mobile) => {
  return axios.post(`${CONFIG.url}/requestOTP`, {
    mobile,
  });
};

export const OTP_verifyAPI = (payload) => {
  return axios.post(`${CONFIG.url}/verifyOTP`, {
    mobile: payload.mobile,
    code: payload.code,
  });
};
