import moment from "moment";
class Config {
  constructor() {
    this.url = `${process.env.REACT_APP_API}/v1/manager`;
    this.ref_link_ctv = "";
    this.ref_link_employee = "";
    this.startDay = "";
    this.endDay = "";
    this.typeScreen = "";
    this.phone_admin = "0982828216";
    this.currentStartDate = "";
    this.currentEndDate = "";
    this.app_total = 0;
    this.corporate_total = 0;
    this.viewProfileUser = "https://i-hr.vn/profile";
  }

  //xs < 576
  //sm : 576 - 768
  //md : 768 - 992
  //lg : 992 - 1200
  //xl : 1200 - 1400
  //xxl : 1400++

  onLinkRef(ref) {
    this.ref_link_ctv = `${process.env.REACT_APP_URL}/dangky?ref=${ref}`;
    this.ref_link_employee = `https://employer.i-hr.vn/auth/register?ref=${ref}`;
  }

  copyToClipboard(text) {
    var textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  }
  //set accessToken refreshToken organize infoUser
  setInfoUser(user, accessToken, refreshToken, organize) {
    this.admin_user_id = user.admin_user_id;
    this.fullName = user.fullName;
    this.gender = user.gender;
    this.mobile = user.mobile;
    this.email = user.email;
    this.identity = user.identity;
    this.company = user.company;
    this.organization_id = user.organization_id;
    this.province_code = user.province_code;
    this.district_code = user.district_code;
    this.parent_id = user.parent_id;
    this.ref_code = user.ref_code;
    this.parent_ref_code = user.parent_ref_code;
    this.username = user.username;
    this.password = user.password;
    this.role = user.role;

    this.accessToken = accessToken || "";
    this.refreshToken = refreshToken || "";
    this.organize = organize || "";
  }

  //set date time
  setDateTime() {
    this.todayStartTime = moment(new Date(), moment.ISO_8601).format(
      "YYYY-MM-DD 00:00:00"
    );
    this.todayEndTime = moment(new Date(), moment.ISO_8601).format(
      "YYYY-MM-DD 23:59:59"
    );
    this.nowWeekStartTime = moment(new Date(), moment.ISO_8601)
      .subtract(6, "days")
      .format("YYYY-MM-DD 00:00:00");
    this.nowWeekEndTime = this.todayEndTime;

    this.nowMonthStartTime = moment(new Date(), moment.ISO_8601)
      .startOf("month")
      .format("YYYY-MM-DD 00:00:00");
    this.nowMonthEndTime = this.todayEndTime;
    this.lastMonthStartTime = moment(new Date(), moment.ISO_8601)
      .subtract(1, "month")
      .startOf("month")
      .format("YYYY-MM-DD 00:00:00");
    // this.lastMonthStartTime = `2019-01-01 00:00:00`;
    this.lastMonthEndTime = moment(new Date(), moment.ISO_8601)
      .subtract(1, "month")
      .endOf("month")
      .format("YYYY-MM-DD 23:59:59");
    this.currentStartDate = this.lastMonthStartTime;
    this.currentEndDate = this.lastMonthEndTime;
    this.beginningDate = '2021:01:01 00:00:00';
  }
}

var CONFIG = new Config();
export default CONFIG;
