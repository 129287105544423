import * as types from "../../type";
import { takeLatest, call, put } from "redux-saga/effects";
import { getProvinceApi } from "../../../api/register.api";
import { getProvinceSuccess } from "../../actions/register.action";

function* getProvinceSaga(action) {
  try {
    const provinces = yield call(getProvinceApi, action.payload);
    yield put(getProvinceSuccess(provinces));
  } catch (err) {
    yield put({
      type: types.PROVINCE_ERROR,
      payload: err.response,
    });
  }
}

export default function* registerSaga() {
  yield takeLatest(types.PROVINCE_REQUEST, getProvinceSaga);
}
