import * as types from "../../type";
import { takeLatest, call, put } from "redux-saga/effects";
import { registerCTVApi } from "../../../api/register.api";
import { submit_CTV_Success } from "../../actions/register.action";

function* submitCTVSaga(action) {
  try {
    const submit = yield call(registerCTVApi, action.payload);
    yield put(submit_CTV_Success(submit));
    alert(
      "Đăng ký thành công. Thông tin chi tiết đã được gửi tới Email của bạn. Trân trọng !"
    );
    window.location.reload();
    // window.location.replace("/login");
  } catch (err) {
    yield put({
      type: types.REGISTER_CTV_ERROR,
      payload: err.response,
    });
  }
}

export default function* submit_registerCTVSaga() {
  yield takeLatest(types.REGISTER_CTV_REQUEST, submitCTVSaga);
}
