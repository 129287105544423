import React, { useCallback, useState, useMemo, useEffect } from "react";
import { Container, Form, Row, Col } from "react-bootstrap";
import CONFIG from "../../config/config";
import { ToastContainer } from "react-toastify";
import Loading from "../../components/Loading";
import Table from "../../components/table/Table";
import DatePicker from "../../components/common/datePicker";
import "./Report.css";
import CustomButton from "../../components/common/button";
import { useDispatch, useSelector } from "react-redux";
// import money_format from "../../config/moneyFormat";
import ExportExcel from "../../components/common/ExportExcel";
import { reportRequest } from "../../redux/actions/report.action";
import { getAdminActivityReportAPi } from "../../api/report.api";
import API from "../../service/api";
// import InfoUserOrganization from "../organization/InfoUserOrganization";
import moment from "moment";
import "../member/Member.css";

let dataConfig = {};

const Report = () => {
	const dispatch = useDispatch();
	const [dataGetBranch, setDataGetBranch] = useState([]);
	const [branchType, setBranchType] = useState(0);
	const [titleToAPI, setTitleToAPI] = useState("");

	const reportStore = useSelector((state) => state.report);

	useEffect(() => {
		API.get(
			`${CONFIG.url}/getBranch?p_admin_user_id=${CONFIG.admin_user_id}`
		).then((response) => {
			setDataGetBranch(response.data.data.getBranchControl);
			const data4 = {
				p_organization_id: 0,
				p_from_date: CONFIG.lastMonthEndTime,
				p_to_date: CONFIG.lastMonthEndTime,
				p_title: "",
				offset: 0,
			};
			dispatch(reportRequest(data4));
		});
	}, [dispatch]);

	const corporateTableHead = useMemo(
		() => [
			"",
			"Mã giới thiệu",
			"Username",
			"Họ và tên",
			"Cơ quan",
			"Điện thoại",
			"APP",
			"Doanh nghiệp",
		],
		[]
	);

	const renderHead = useCallback(
		(item, index) => (
			<th style={{ border: "1px solid #ccc" }} key={index}>
				{item}
			</th>
		),
		[]
	);
	//.replace("T", " ").replace(".000Z", "")
	const renderBody = useCallback(
		(item, index) => (
			<tr key={index}>
				<td style={{ border: "1px solid #ccc", textAlign: "center" }}>
					{index + 1}
				</td>
				<td style={{ border: "1px solid #ccc" }}>{item.ref_code}</td>
				<td style={{ border: "1px solid #ccc" }}>{item.username}</td>
				<td style={{ border: "1px solid #ccc" }}>{item.full_name}</td>
				<td style={{ border: "1px solid #ccc" }}>{item.org_name}</td>
				<td style={{ border: "1px solid #ccc" }}>{item.mobile}</td>
				<td style={{ border: "1px solid #ccc", textAlign: "center" }}>
					{item.app_total}
				</td>
				<td style={{ border: "1px solid #ccc", textAlign: "center" }}>
					{item.corporate_total}
				</td>
			</tr>
		),
		[]
	);

	const changeStartDate = useCallback(
		(e) => {
			CONFIG.currentStartDate = moment(e, moment.ISO_8601).format(
				"YYYY-MM-DD 00:00:00"
			);
			setTimeout(() => {
				const data = {
					p_organization_id: branchType,
					p_from_date: CONFIG.currentStartDate,
					p_to_date: CONFIG.currentEndDate,
					p_title: titleToAPI,
					offset: 0,
				};
				dispatch(reportRequest(data));
			}, 1000);
		},
		[branchType, titleToAPI, dispatch]
	);

	const changeEndDate = useCallback(
		(e) => {
			CONFIG.currentEndDate = moment(e, moment.ISO_8601).format(
				"YYYY-MM-DD 23:59:59"
			);
			setTimeout(() => {
				const data = {
					p_organization_id: branchType,
					p_from_date: CONFIG.currentStartDate,
					p_to_date: CONFIG.currentEndDate,
					p_title: titleToAPI,
					offset: 0,
				};
				dispatch(reportRequest(data));
			}, 1000);
		},
		[dispatch, branchType, titleToAPI]
	);

	const onChangeTypeBranch = (value, branch) => {
		const data = {
			p_organization_id: branch,
			p_from_date: CONFIG.currentStartDate,
			p_to_date: CONFIG.currentEndDate,
			p_title: value,
			offset: 0,
		};
		dispatch(reportRequest(data));
	};

	const onClickButtonCalendar = useCallback(
		(e) => {
			//1 hôm nay
			//2 7 ngay gan nhat
			//3 tháng này
			//default tháng trước
			switch (e) {
				case 1:
					CONFIG.currentStartDate = CONFIG.todayStartTime;
					CONFIG.currentEndDate = CONFIG.todayEndTime;
					dataConfig = {
						p_organization_id: branchType,
						p_from_date: CONFIG.currentStartDate,
						p_to_date: CONFIG.currentEndDate,
						p_title: titleToAPI,
						offset: 0,
					};
					dispatch(reportRequest(dataConfig));
					return;

				case 2:
					CONFIG.currentStartDate = CONFIG.nowWeekStartTime;
					CONFIG.currentEndDate = CONFIG.nowWeekEndTime;
					dataConfig = {
						p_organization_id: branchType,
						p_from_date: CONFIG.currentStartDate,
						p_to_date: CONFIG.currentEndDate,
						p_title: titleToAPI,
						offset: 0,
					};
					dispatch(reportRequest(dataConfig));
					return;

				case 3:
					CONFIG.currentStartDate = CONFIG.nowMonthStartTime;
					CONFIG.currentEndDate = CONFIG.nowMonthEndTime;
					dataConfig = {
						p_organization_id: branchType,
						p_from_date: CONFIG.currentStartDate,
						p_to_date: CONFIG.currentEndDate,
						p_title: titleToAPI,
						offset: 0,
					};
					dispatch(reportRequest(dataConfig));
					return;

				case 4:
					CONFIG.currentStartDate = CONFIG.beginningDate;
					CONFIG.currentEndDate = CONFIG.todayEndTime;
					dataConfig = {
						p_organization_id: branchType,
						p_from_date: CONFIG.currentStartDate,
						p_to_date: CONFIG.currentEndDate,
						p_title: titleToAPI,
						offset: 0,
					};
					dispatch(reportRequest(dataConfig));
					return;

				default:
					CONFIG.currentStartDate = CONFIG.lastMonthStartTime;
					CONFIG.currentEndDate = CONFIG.lastMonthEndTime;
					dataConfig = {
						p_organization_id: branchType,
						p_from_date: CONFIG.currentStartDate,
						p_to_date: CONFIG.currentEndDate,
						p_title: titleToAPI,
						offset: 0,
					};
					dispatch(reportRequest(dataConfig));
					return;
			}
		},
		[branchType, titleToAPI, dispatch]
	);

	const paginateReport = (offset) => {
		const dataAll = {
			p_organization_id: branchType,
			p_from_date: CONFIG.currentStartDate,
			p_to_date: CONFIG.currentEndDate,
			p_title: titleToAPI,
			offset: offset,
		};
		dispatch(reportRequest(dataAll));
	};

	const exportToExcel = async () => {
		let excelReport = [];
		//Lay tat ca
		dataConfig.limit = 10000000;

		excelReport = await getAdminActivityReportAPi(dataConfig);

		let arrayExcel = [];
		excelReport.data.data.getActivityReport.map((item) =>
			arrayExcel.push({
				"Mã giới thiệu": item.ref_code,
				Username: item.username,
				"Họ và tên": item.full_name,
				"Cơ quan": item.org_name,
				"Điện thoại": item.mobile,
				APP: item.app_total,
				"Doanh nghiệp": item.corporate_total,
			})
		);
		let getMonth = moment(CONFIG.currentStartDate, moment.ISO_8601).format(
			"MM-YYYY"
		);
		if (getMonth === "Invalid date") {
			getMonth = "Tất cả";
		}
		ExportExcel(arrayExcel, `iHrHoatDong_Thang${getMonth}`);
	};

	return (
		<Container className="col-12">
			{/* board  */}
			{/* <InfoUserOrganization /> */}
			{/* DatePicker */}
			<div lg={12} className="optional_container">
				{/* select */}
				<Row
					xs={12}
					lg={12}
					md={12}
					xl={12}
					style={{
						flexDirection: "row",
					}}
				>
					{/* Loai */}
					<Col xs={12} lg={4} md={3} xl={2} xxl={3}>
						<Form.Label htmlFor="basic-url" style={{ fontWeight: "bold" }}>
							Chi nhánh
						</Form.Label>
						<Form.Select
							value={titleToAPI}
							onChange={(e) => {
								setTitleToAPI(e.target.value);
								setBranchType(
									e.target.selectedIndex <= 0
										? 0
										: parseInt(
												dataGetBranch[e.target.selectedIndex - 1]
													.organization_id
										  )
								);
								onChangeTypeBranch(
									e.target.value,
									e.target.selectedIndex <= 0
										? 0
										: parseInt(
												dataGetBranch[e.target.selectedIndex - 1]
													.organization_id
										  )
								);
							}}
							xs={12}
							lg={12}
						>
							<option value="">Tất cả</option>
							{dataGetBranch &&
								dataGetBranch.length !== 0 &&
								dataGetBranch.map((item, index) => (
									<option key={index} value={item.title}>
										{item.title}
									</option>
								))}
						</Form.Select>
					</Col>

					{/* thoi gian */}
					<Col xs={12} lg={7} md={9} xl={5} xxl={0}>
						<Form.Label
							style={{
								fontWeight: "bold",
								width: "auto",
							}}
						>
							Thời gian
						</Form.Label>
						<Row
							style={{
								width: "100%",
							}}
						>
							<DatePicker
								top={CONFIG.typeScreen === "xs" ? 10 : 0}
								xl={6}
								lg={6}
								md={6}
								currentDate={CONFIG.currentStartDate}
								valueDatePicker={changeStartDate}
							/>
							<DatePicker
								top={CONFIG.typeScreen === "xs" ? 10 : 0}
								xl={6}
								md={6}
								lg={6}
								currentDate={CONFIG.currentEndDate}
								valueDatePicker={changeEndDate}
							/>
						</Row>
					</Col>

					{/* button */}
					<Col
						xs={12}
						lg={12}
						md={12}
						xl={5}
						xxl={4}
						style={{
							flexDirection:
								CONFIG.typeScreen === "xs" || CONFIG.typeScreen === "sm"
									? "column"
									: "row",
							alignItems:
								CONFIG.typeScreen === "xs" || CONFIG.typeScreen === "sm"
									? "start"
									: "center",
							display:
								CONFIG.typeScreen === "xs" || CONFIG.typeScreen === "sm"
									? "inline"
									: "flex",
							justifyContent: "space-around",
							marginTop:
								CONFIG.typeScreen === "xl" || CONFIG.typeScreen === "xxl"
									? 30
									: CONFIG.typeScreen === "lg"
									? 10
									: 5,
						}}
					>
						<CustomButton
							title="Hôm nay"
							backgroundColor="#B9B9B9"
							textColor="#000"
							onClick={() => {
								onClickButtonCalendar(1);
							}}
						/>

						<CustomButton
							title="7 ngày"
							backgroundColor="#B9B9B9"
							textColor="#000"
							onClick={() => {
								onClickButtonCalendar(2);
							}}
						/>

						<CustomButton
							title="Tháng này"
							backgroundColor="#B9B9B9"
							textColor="#000"
							onClick={() => {
								onClickButtonCalendar(3);
							}}
						/>

						<CustomButton
							title="Tất cả"
							backgroundColor="#B9B9B9"
							textColor="#000"
							onClick={() => {
								onClickButtonCalendar(4);
							}}
						/>

						{/* <CustomButton
              title="Tháng trước"
              backgroundColor="#B9B9B9"
              textColor="#000"
              onClick={() => {
                onClickButtonCalendar(4);
              }}
            /> */}
					</Col>
				</Row>
			</div>
			{/* table */}
			<div className="row" style={{ marginTop: 10 }}>
				<div className="col-12">
					<div className="card">
						{reportStore.getActivityReport &&
							reportStore.getActivityReport.length !== 0 && (
								<Col
									xs={12}
									style={{
										display:
											CONFIG.typeScreen === "xs" ||
											CONFIG.typeScreen === "sm" ||
											CONFIG.typeScreen === "md"
												? "inline"
												: "flex",
										flexDirection:
											CONFIG.typeScreen === "xs" || CONFIG.typeScreen === "sm"
												? "column"
												: "row",
										justifyContent: "space-between",
									}}
								>
									<Form.Group>
										<Form.Label style={{ fontWeight: "bold" }}>
											APP:&ensp;
										</Form.Label>
										{/* <Form.Label>{renderNumberApp()}</Form.Label> */}
										<Form.Label>{reportStore.app_total}</Form.Label>
									</Form.Group>

									<Form.Group>
										<Form.Label
											style={{
												fontWeight: "bold",
											}}
										>
											Doanh nghiệp:&ensp;
										</Form.Label>
										<Form.Label>{reportStore.corporate_total}</Form.Label>
										{/* <Form.Label>{renderNumberRevenue()}</Form.Label> */}
									</Form.Group>

									<Form.Group>
										<Form.Label
											style={{
												fontWeight: "bold",
											}}
										>
											{/* Tổng:&ensp; */}
										</Form.Label>
										<Form.Label>
											{/* {money_format(reportStore.getActivityReport.totalAppCorporate)}&nbsp;đ */}
										</Form.Label>
									</Form.Group>

									<CustomButton
										title="Xuất ra Excel"
										backgroundColor="#ffff"
										textColor="#B50829"
										onClick={exportToExcel}
									/>
								</Col>
							)}

						<div className="card__body">
							{reportStore.getActivityReport &&
							reportStore.getActivityReport.length !== 0 ? (
								<Table
									limit="50"
									headData={corporateTableHead}
									renderHead={(item, index) => renderHead(item, index)}
									bodyData={reportStore.getActivityReport}
									renderBody={(item, index) => renderBody(item, index)}
									count={reportStore.activity_total}
									paginateReport={paginateReport}
								/>
							) : (
								<h2 style={{ marginTop: "30px", textAlign: "center" }}>
									Hiện không có thông tin
								</h2>
							)}
						</div>
					</div>
				</div>
			</div>
			<ToastContainer />
			<Loading isLoading={reportStore.loading} />
		</Container>
	);
};

export default Report;
