import * as types from "../type";

const initialState = {
  province: [],
  titlePartner: "",
  status: "",
  loading: false,
  check_mobile: null,
  ref_code: "",
};

const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PROVINCE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.PROVINCE_SUCCESS:
      return {
        ...state,
        province: action.payload.data.data.province,
        loading: false,
      };

    //twd
    case types.REGISTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        // ref_code: action.payload.data.data.submitRegister[0].p_next_ref_code,
      };
    case types.REGISTER_ERROR:
      return {
        ...state,
        loading: false,
      };

    //ctv
    case types.REGISTER_CTV_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.REGISTER_CTV_SUCCESS:
      return {
        ...state,
        loading: false,
        ref_code: action.payload.data.data.submitRegisterCTV[0].p_next_ref_code,
      };
    case types.REGISTER_CTV_ERROR:
      return {
        ...state,
        loading: false,
      };

    //check mobile
    case types.CHECK_MOBILE_REQUEST:
      return {
        ...state,
        loading: true,
        check_mobile: false,
      };
    case types.CHECK_MOBILE_SUCCESS:
      return {
        ...state,
        loading: false,
        check_mobile: action.payload[0].chkmob,
      };
    case types.CHECK_MOBILE_ERROR:
      return {
        ...state,
        loading: false,
        check_mobile: false,
      };

    default:
      return {
        ...state,
        loading: false,
      };
  }
};

export default registerReducer;
