import axios from "axios";
import CONFIG from "../config/config";

export const customerAPI = (body) => {
	return axios.get(`
  ${CONFIG.url}/getListCustomerJHL?p_admin_user_id=${CONFIG.admin_user_id}&p_ref_code=${body.refPerson}&p_status=${body.status}&p_province_code=${body.provinceValue}&p_name=${body.namePerson}&p_from_date=${body.currentStartDate}&p_to_date=${body.currentEndDate}&offset=${body.offset}&limit=${body.limit}
  `);
};

export const customerTotalAPI = (body) => {
	return axios.get(`
  ${CONFIG.url}/getListCustomerJHLTotal?p_admin_user_id=${CONFIG.admin_user_id}&p_ref_code=${body.refPerson}&p_status=${body.status}&p_province_code=${body.provinceValue}&p_name=${body.namePerson}&p_from_date=${body.currentStartDate}&p_to_date=${body.currentEndDate}
  `);
};
