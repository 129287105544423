export const checkMobile = (phone) => {
  if (phone.match(/(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/)) {
    return true;
  }
  return false;
};

export const checkEmail = (phone) => {
  if (
    phone.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  ) {
    return true;
  }
  return false;
};
