import axios from "axios";

const API = axios.create({
  baseURL: `${process.env.REACT_APP_API}/v1/`,
  transformRequest: [(data) => JSON.stringify(data)],
  headers: {
    // 'Authorization' : _token,
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
export default API;
