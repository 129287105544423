import React from "react";

import "./topnav.css";

import { Link } from "react-router-dom";

import Dropdown from "../dropdown/Dropdown";

// import ThemeMenu from "../thememenu/ThemeMenu";

import notifications from "../../assets/JsonData/notification.json";

import user_image from "../../assets/images/Logo_dark.jpg";

import user_menu from "../../assets/JsonData/user_menus.json";

const Topnav = () => {
  const accessToken = localStorage.getItem("accessToken");
  const infoUser = JSON.parse(localStorage.getItem("infoUser"));
  if (!infoUser || !accessToken) {
    return window.location.href.replace("/login");
  }

  const renderUserToggle = () => (
    <div className="topnav__right-user">
      <div className="topnav__right-user__image">
        <img src={user_image} alt="" />
      </div>
      <div className="topnav__right-user__name">{infoUser.fullName}</div>
    </div>
  );

  const renderNotificationItem = (item, index) => (
    <div className="notification-item" key={index}>
      <i className={item.icon}></i>
      <span>{item.content}</span>
    </div>
  );

  const renderUserMenu = (item, index) => (
    <Link to="/" key={index}>
      <div className="notification-item">
        {/* <i className={item.icon}></i> */}
        <span>{item.content}</span>
      </div>
    </Link>
  );

  const onClickSidebar = ()=>{
    if(window.innerWidth > 576){
      return;
    }
    console.log('khanh click');
  }

  return (
    <div className="topnav">
      <div className="topnav__search">
        {/* <input type="text" placeholder="Search here..." /> */}
        <i className="bx bx-search"></i>
      </div>
      { window.innerWidth < 576 && <div className="topnav__menu">
        <i onClick={onClickSidebar} className='bx bx-menu'></i>
      </div>}
      <div className="topnav__right">
        <div className="topnav__right-item">
          {/* dropdown here */}
          <Dropdown
            customToggle={() => renderUserToggle()}
            contentData={user_menu}
            renderItems={(item, index) => renderUserMenu(item, index)}
          />
        </div>
        <div className="topnav__right-item">
          <Dropdown
            icon="bx bx-log-out"
            // badge="12"
            contentData={notifications}
            renderItems={(item, index) => renderNotificationItem(item, index)}
            renderFooter={() => <Link to="/">View All</Link>}
          />
          {/* dropdown here */}
        </div>
        <div className="topnav__right-item">
          {/* <ThemeMenu /> */}
        </div>
      </div>
    </div>
  );
};

export default Topnav;
