import * as types from "../../type";
import { takeLatest, call, put } from "redux-saga/effects";
import { getBranchApi } from "../../../api/report.api";
import { getBranchSuccess, getBranchError } from "../../actions/report.action";

function* getBranch(action) {
  try {
    const submit = yield call(getBranchApi, action.payload);
    yield put(getBranchSuccess(submit));
  } catch (err) {
    yield put(getBranchError(err));
  }
}

export default function* GetBranchSaga() {
  yield takeLatest(types.GET_BRANCH_REQUEST, getBranch);
}
