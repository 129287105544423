import React from "react";
import { Modal } from "react-bootstrap";

const ErrorRefCode = () => {
  return (
    <Modal.Dialog>
      <Modal.Header>
        <Modal.Title>Mã giới thiệu không tồn tại</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Vui lòng liên hệ quản trị viên: 0982828216</p>
      </Modal.Body>
    </Modal.Dialog>
  );
};

export default ErrorRefCode;
